import React from 'react'

import { Typography, Box, Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import constants from '../libs/constants'
const pageSize = constants.pageSize

function Paging({ totalCount, page, onPageChange }) {
    if (totalCount === 0) {
        return false
    }
    return (
        <Box m={4}>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={2}>
                    <Typography component="span">{pageSize * (page - 1) + 1}-{pageSize * page > totalCount ? totalCount : pageSize * page} of {totalCount}</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Pagination color="primary" size="small" count={Math.ceil(totalCount / pageSize)} page={page} onChange={onPageChange} showFirstButton showLastButton/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Paging