import getAxios from './axios'

const get = (date, source) => getAxios({
    url: `/groupattendances/item/${date}/${source}`,
    method: 'GET',
})

const list = (start, end, source) => getAxios({
    url: `/groupattendances/range/${start}/${end}/${source}`,
    method: 'GET',
})

const create = (userId, userName, date, source, sendObj) => getAxios({
    url: `/groupattendances/${date}`,
    method: 'PATCH',
    data: {
        userId,
        userName,
        source,
        data: JSON.stringify(sendObj),
    },
})

const remove = (date, source) => getAxios({
    url: `/groupattendances/${date}/${source}`,
    method: 'DELETE',
})

const groupEducations = {
    get,
    list,
    create,
    remove,
}

export default groupEducations