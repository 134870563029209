import getAxios from './axios'

const list = () => getAxios({
    url: '/vitaltemplates',
    method: 'GET',
})

const create = (userId, userName, label, chineseContent, englishContent) => getAxios({
    url: '/vitaltemplates',
    method: 'PUT',
    data: {
        userId,
        userName,
        label,
        chineseContent,
        englishContent,
    },
})

const update = (templateId, userId, userName, label, chineseContent, englishContent) => getAxios({
    url: `/vitaltemplates/${templateId}`,
    method: 'PUT',
    data: {
        userId,
        userName,
        label,
        chineseContent,
        englishContent,
    },
})

const remove = (templateId) => getAxios({
    url: `/vitaltemplates/${templateId}`,
    method: 'DELETE',
})

const vitalTemplates = {
    list, 
    create,
    update,
    delete: remove,
}

export default vitalTemplates