import React, { useState, useMemo } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import {
    Grid,
    makeStyles,
    TextField,
    Button,
    Card,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    InputAdornment,
    IconButton,
    FormHelperText,
} from '@material-ui/core'
import DateDayjsUtils from '@date-io/dayjs'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

import api from '../../api'

import ColoredButton from '../ColoredButton'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import routes from '../../api/routes'
import utils from '../../utils'

const useStyles = makeStyles({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
  })

  function GroupForm(props) {
      const { onSelectedCreatedHandler, onCancelClick, periOpReason, physioReason, attendancespeciality, date, user, room } = props
      const classes = useStyles({ color: room.color })
      const { handleSubmit, formState: { errors }, control } = useForm({
          defaultValues: props.groupData || {},
          reValidateMode: 'onSubmit',
      })
      const currentAttendances = useWatch({
        control,
        name: 'attendances',
      })

      const [selectedDate, setSelectedDate] = useState(date || dayjs().format('YYYY-MM-DD'))
      
      const [attendances, setAttendances] = useState(props.groupData?.attendances || [])
      const [groupData, setGroupData] = useState(props.groupData || {
          onlineNPAC: 0,
      })

      const totalPatients = useMemo(() => {
        return currentAttendances ? currentAttendances.reduce((acc, cur) => {
          return acc + (cur.patient ? parseInt(cur.patient) : 0)
        }, 0) : 0
      }, [currentAttendances])

      const totalRelatives = useMemo(() => { 
        return currentAttendances ? currentAttendances.reduce((acc, cur) => {
          return acc + (cur.relative ? parseInt(cur.relative) : 0)
        }, 0) : 0
      }, [currentAttendances])

      const totalAttendances = useMemo(() => {
        return totalPatients + totalRelatives
      }, [totalPatients, totalRelatives])

      const groupEducationLabel = useMemo(() => {
        if (!room) return ''
        if (room.key === utils.constants.room_identifier.surgery_school) return 'Group Education'
        if (room.key === utils.constants.room_identifier.npac) return 'Number of Patient Attendance'
        return ''
      }, [room])

      const shouldShowRelative = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
      }, [room])

      const shouldShowGroupEducation = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return true
        return false
      }, [room])

      const shouldShowReasonConsultation = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
      }, [room])

      const shouldShowOther = useMemo(() => {
        if (!room) return false
        if (room.key === utils.constants.room_identifier.surgery_school) return true
        if (room.key === utils.constants.room_identifier.npac) return false
        return false
      }, [room])


      const [updatingGroup, setUpdatingGroup] = useState(false)
      const [errorMessage, setErrorMessage] = useState('')

      function onSelectedDateChange(date) {
        setSelectedDate(date)
      }

      function addAttendance() {
        const newAttendances = JSON.parse(JSON.stringify(attendances))
        newAttendances.push({
          speciality: -1,
          patient: 0,
          relative: 0,
          deleted: false,
        })
        setAttendances(() => newAttendances)
      }

      function removeAttendance(index) {
        setAttendances(attendances.map((r, i) => {
          if (i === index) {
            r.deleted = true
          }

          return r
        }))
      }

      async function onSubmitHandler(data) {
        const source = room.key
          const currentSelectedDate = dayjs(selectedDate).format('YYYY-MM-DD')
          const response = await routes.groupEducations.get(currentSelectedDate, source)
          if (response.data && response.data.exist) {
            if (response.data.attendance.id) {
              if (!(window.confirm('You have already created data for this date. Do you want to update it?'))) {
                return
              }
            }
          }
        
          let sendObj = []
          let hasError = false
          let addedAttendance = {}
          setErrorMessage(null)
          for (let key in data) {
            if (key === 'attendances') {
              if (room.key === utils.constants.room_identifier.surgery_school || 
                  room.key === utils.constants.room_identifier.npac
                ) {
                  const attendancesData = data[key]
                  attendancesData.forEach((atte, index) => {
                    sendObj.push({
                      reason_id: parseInt(atte.specialty),
                      name: 'patient',
                      number: parseInt(atte.patient),
                    })
                    if (shouldShowRelative) {
                      sendObj.push({
                        reason_id: parseInt(atte.specialty),
                        name: 'relative',
                        number: parseInt(atte.relative),
                      })
                    }
                    
                    const id = parseInt(atte.specialty)
                    if (!id) {
                      setErrorMessage('specialty in patient attendance cannot be empty')
                      hasError = true
                    } else if (addedAttendance[id]) {
                      setErrorMessage('cannot have duplicate specialty in patient attendance')
                      hasError = true
                    } else {
                      addedAttendance[id] = true
                    }
                  })
              }
            } else if (key === 'reason') {
              if (room.key === utils.constants.room_identifier.surgery_school) {
                const reasonData = data[key]
                reasonData.forEach((reas, index) => {
                  sendObj.push({
                    reason_id: index,
                    name: '',
                    number: parseInt(reas),
                  })
                })
              }
            } else {
              if (room.key === utils.constants.room_identifier.surgery_school) {
                const row = parseInt(data[key])
                sendObj.push({
                  name: key, 
                  number: row,
                })
              }
            }    
          }

          if (hasError) {
            return
          } 

          try {
            const education = await api.routes.groupEducations.create(
              user.id,
              user.name,
              currentSelectedDate,
              source,
              sendObj,
            )

              onSelectedCreatedHandler && onSelectedCreatedHandler(education.data)
          } catch (err) {
              console.log(err)
              alert('Error occurs when saving the group education')
          } finally {
              setUpdatingGroup(false)
          }
      }

      return (
          <>
            <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={3} fullWidth>
                    <Card variant="outlined" style={{
                      width: '100%',
                      padding: '10px',
                      marginBottom: '10px',
                    }}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Selected Date
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <MuiPickersUtilsProvider utils={DateDayjsUtils}>
                              <KeyboardDatePicker
                                  variant="contained"
                                  fullWidth
                                  margin="normal"
                                  label="Selected Date"
                                  format="YYYY-MM-DD"
                                  value={selectedDate}
                                  onChange={onSelectedDateChange}
                              />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    </Card>
                    {shouldShowGroupEducation && (
                      <Card variant="outlined" style={{ 
                        width: '100%',
                        padding: '10px',
                        marginBottom: '10px',
                      }}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {groupEducationLabel}
                        </Typography>
                        <Grid container spacing={3}>
                          {attendances.map((attendance, index) => {
                            if (attendance.deleted) {
                              return false
                            } 
                            return (
                              <>
                                <Grid item xs={3}>
                                <FormControl 
                                  margin="normal" 
                                  fullWidth 
                                  variant="outlined" 
                                  className={classes.select}
                                  error={!!errors[`attendances[${index}].specialty`]}
                                >
                                    <Controller
                                        control={control}
                                        rules={{ required: false }}
                                        name={`attendances[${index}].specialty`}
                                        defaultValue={attendance.specialty}
                                        as={
                                          <TextField
                                            select
                                            variant="outlined"
                                            label="Attendance Specialty"
                                            error={!!errors[`attendances[${index}].specialty`]}
                                            fullWidth
                                            disabled={updatingGroup}
                                        >
                                          {
                                            attendancespeciality.map((specialty, index2) => (
                                              <MenuItem value={specialty.id} key={`choice${index2}`}>{specialty.content}</MenuItem>
                                            ))
                                          }
                                        </TextField>
                                        }
                                    ></Controller>
                                    {errors[`attendances[${index}].specialty`] && <FormHelperText>{errors[`attendances[${index}].specialty`]}</FormHelperText>}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={shouldShowRelative ? 3 : 9}>
                                  <Controller
                                      control={control}
                                      name={`attendances[${index}].patient`}
                                      defaultValue={0}
                                      as={
                                          <TextField
                                              variant="outlined"
                                              margin="normal"
                                              fullWidth
                                              type="number"
                                              label={'patient attendance'}
                                              className={classes.textField}
                                              error={!!errors[`attendances[${index}].patient`]}
                                              disabled={updatingGroup}
                                          ></TextField>
                                      }
                                  ></Controller>
                                </Grid>
                                {shouldShowRelative && (
                                  <>
                                    <Grid item xs={3}>
                                      <Controller
                                          control={control}
                                          name={`attendances[${index}].relative`}
                                          defaultValue={0}
                                          as={
                                              <TextField
                                                  variant="outlined"
                                                  margin="normal"
                                                  fullWidth
                                                  type="number"
                                                  label={'relative attendance'}
                                                  className={classes.textField}
                                                  error={!!errors[`attendances[${index}].relative`]}
                                                  disabled={updatingGroup}
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <IconButton
                                                          size="small"
                                                          onClick={() => removeAttendance(index)}
                                                        >
                                                          <CloseIcon />
                                                        </IconButton>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                              ></TextField>
                                          }
                                      ></Controller>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography className={classes.title} color="textSecondary" gutterBottom style={{
                                        marginTop: '30px',
                                      }}>
        
                                        Total: {currentAttendances && (
                                          (currentAttendances[index]?.patient ? parseInt(currentAttendances[index]?.patient) : 0) + 
                                          (currentAttendances[index]?.relative ?parseInt(currentAttendances[index]?.relative) : 0))}
                                      </Typography>
                                    </Grid>
                                  </>
                                )}
                              </>
                            )
                          })}
                          <Grid item xs={3}>
                          </Grid>
                          <Grid item xs={shouldShowRelative ? 3 : 9}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom style={{
                                    marginTop: '30px',
                                  }}>
                              Total Patients: {totalPatients}
                            </Typography>
                          </Grid>
                          {shouldShowRelative && (
                            <>
                              <Grid item xs={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom style={{
                                        marginTop: '30px',
                                      }}>
                                  Total Relatives: {totalRelatives}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom style={{
                                        marginTop: '30px',
                                      }}>
                                  Total: {totalAttendances}
                                </Typography>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12}>
                            <ColoredButton
                              color={room.color}
                              textColor="white"
                              margin="normal"
                              fullWidth
                              variant="contained"
                              disabled={updatingGroup}
                              onClick={addAttendance}
                            >Add Speciality</ColoredButton>
                          </Grid>
                        </Grid>
                      </Card>
                    )}

                    {shouldShowReasonConsultation && (
                      <>
                        <Card variant="outlined" style={{ 
                          width: '100%',
                          padding: '10px',
                          marginBottom: '10px',
                        }}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Consultation by Peri-Op Nurse
                          </Typography>
                          <Grid container spacing={3}>
                          {
                            periOpReason.map((reason, index) => {
                              return (
                                <Grid item xs={12} md={6} lg={6} key={index}>
                                  <Controller
                                      control={control}
                                      name={`reason[${reason.id}]`}
                                      defaultValue={groupData[`reason${reason.id}`] || 0}
                                      as={
                                          <TextField
                                              variant="outlined"
                                              margin="normal"
                                              fullWidth
                                              type="number"
                                              label={reason.content}
                                              className={classes.textField}
                                              error={!!errors[`reason.${reason.id}`]}
                                              disabled={updatingGroup}
                                          ></TextField>
                                      }
                                  ></Controller>
                                </Grid>
                              )
                            })
                          }
                          </Grid>
                        </Card>
                        <Card variant="outlined" style={{ 
                          width: '100%',
                          padding: '10px',
                          marginBottom: '10px',
                        }}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Consultation by Physiotherapist
                          </Typography>
                          <Grid container spacing={3}>
                          {
                            physioReason.map((reason, index) => {
                              return (
                                <Grid item xs={12} md={6} lg={6} key={index}>
                                  <Controller
                                      control={control}
                                      name={`reason[${reason.id}]`}
                                      defaultValue={groupData[`reason${reason.id}`] || 0}
                                      as={
                                          <TextField
                                              variant="outlined"
                                              margin="normal"
                                              type="number"
                                              fullWidth
                                              label={reason.content}
                                              className={classes.textField}
                                              error={!!errors[`reason.${reason.id}`]}
                                              disabled={updatingGroup}
                                          ></TextField>
                                      }
                                  ></Controller>
                                </Grid>
                              )
                            })
                          }
                          </Grid>
                        </Card>
                      </>
                    )}
                    
                    {shouldShowOther && (
                      <Card variant="outlined" style={{ 
                        width: '100%',
                        padding: '10px',
                        marginBottom: '10px',
                      }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} lg={6}>
                            <Controller
                              control={control}
                              name={'onlineNPAC'}
                              defaultValue={groupData.onlineNPAC}
                              as={
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  type="number"
                                  fullWidth
                                  label="Online NPAC"
                                  className={classes.textField}
                                  error={!!errors.onlineNPAC}
                                  disabled={updatingGroup}
                                ></TextField>
                              }
                            ></Controller>
                          </Grid>
                        </Grid>
                      </Card>
                    )}
                    {errorMessage && ( <div
                      style={{
                        color: 'red',
                      }}
                    >
                        {errorMessage}
                      </div>
                    )}
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button
                                    color="secondary"
                                    margin="normal"
                                    fullWidth
                                    variant="contained"
                                    onClick={onCancelClick}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs>
                                <ColoredButton
                                    color={room.color}
                                    textColor="white"
                                    margin="normal"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={updatingGroup}
                                >{!!props.groupData ? 'Update' : 'Create'}</ColoredButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
          </>
      )
  }

  export default connect(state => ({
    room: state.room,
    user: state.user,
  }))(GroupForm)