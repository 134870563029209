import React from 'react'
import { 
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    select: ({ color }) => ({
        borderColor: color,
        '&:focus': {
          borderColor: color,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: color,
        },
        '& label.Mui-focused': {
          color: color,
        },
      }),
}))

function StatusSelect({ currentStatus, setStatus, color }) {
    const classes = useStyles({ color: color })
    return (
        <FormControl margin="normal" fullWidth variant="outlined" className={classes.select}>
            <InputLabel>Status</InputLabel>
            <Select
                label="Status"
                value={currentStatus}
                onChange={setStatus}
            >
                <MenuItem value="upcoming">Upcoming</MenuItem>
                <MenuItem value="all">All Cases</MenuItem>
                {/* <MenuItem value="both">Seen & Cancelled</MenuItem> */}
                <MenuItem value="seen">Seen</MenuItem>
                <MenuItem value="canceled">Cancelled</MenuItem>
            </Select>   
        </FormControl>
    )
}

export default StatusSelect