import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import {
  Typography,
  Box,
} from '@material-ui/core'

export default function TableHeader(props) {
  const { date, bookings } = props
  const picked = dayjs(date)
  const today = dayjs()
  const todayBookings = useMemo(() => {
    return bookings.filter(b => today.format('YYYY-MM-DD') === dayjs(b.time).format('YYYY-MM-DD'))
  }, [bookings, today])
  let content = ''

  if (today.format('YYYY-MM-DD') === picked.format('YYYY-MM-DD')) {
    content = (
      <>
        <Typography display="inline" color="secondary" variant="h5">
          Today
        </Typography>
        <Box ml={1}></Box>
        <Typography display="inline" variant="h6">
           Bookings: {todayBookings ? todayBookings.length : 0} { picked.format('YYYY-MM-DD') }
        </Typography>
      </>
    )
  } else {
    content = (
      <>
        <Typography display="inline" color="primary" variant="h5">
          { picked.format('YYYY-MM-DD') }
        </Typography>
        <Box ml={1}></Box>
        <Typography display="inline" variant="h6">
          Booked: {bookings ? bookings.length : 0}
        </Typography>
      </>
    )
  }


  return (
    <Box p={1}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
        {content}
      </div>
    </Box>
  )
}