import getAxios from './axios'

const list = (year, month) => getAxios({
    url: `/summarys/bymonth/${year}/${month}`,
    method: 'GET',
})

const range = (from, to, options) => {
    const params = {
        from,
        to,
    }

    if (options) {
        if (typeof options.revisitPatientOnly === 'boolean') {
            params.revisitPatientOnly = options.revisitPatientOnly
        }
    }

    return getAxios({
        url: '/summarys/byrange',
        method: 'GET',
        params,
    })
}

const summarys = {
    list,
    range,
}

export default summarys