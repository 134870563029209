import React from 'react'
import {
  Grid,
} from '@material-ui/core'

import api from '../api'
import useWebSocket from '../hooks/useWebSocket'

import Messenger from './Messenger/Messenger'

export default function MessengerPanel(props) {
  const {
    code,
    patientList,
    messages,
    addMessage,
    color,
  } = props
  const webSocket = useWebSocket()

  function sendMessage(sender, receiver, message) {
    webSocket.sendWebsocketMessage(api.ws.newTextMessageIntent(sender, receiver, message))
    addMessage(receiver, sender, message)
  }

  return (
    <Grid container spacing={2}>
      { patientList.map(p => {
        return (
          <Grid item xs={12} sm={6} md={4} key={p.code}>
            <Messenger
              sender={code}
              receiver={p.code}
              receiverName={p.name}
              messages={messages[p.code]}
              color={color}
              onSendMessage={sendMessage}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}