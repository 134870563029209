import React, { useState } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
    Grid,
    Typography,
    Button,
    Box,
    LinearProgress,
    Card,
    Toolbar,
    Divider,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import DateDayjsUtils from '@date-io/dayjs'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

import api from '../../api'
import * as XLSX from 'xlsx'

import utils from '../../utils/index'
import { vitalList } from '../../libs/constants'

const fileExtension = '.xlsx'
const dateFormat = 'YYYY-MM-DD'

const input1Regex = new RegExp(utils.getInputFieldConstant(1), 'g')
const input2Regex = new RegExp(utils.getInputFieldConstant(2), 'g')
const input3Regex = new RegExp(utils.getInputFieldConstant(3), 'g')
const input4Regex = new RegExp(utils.getInputFieldConstant(4), 'g')

function AdminSummary() {
    const [loading, setLoading] = useState(false)
    const [revisitPatientOnly, setRevisitPatientOnly] = useState(false)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())

    const onStartDateChange = (date) => {
        setStartDate(date)
    }

    const onEndDateChange = (date) => {
        setEndDate(date)
    }

    const fetchSummary = async () => {
        if (!loading) {
            setLoading(true)

            const res = await api.routes.summarys.range(
                startDate.format(dateFormat),
                endDate.format(dateFormat),
                {
                    revisitPatientOnly,
                },
            )

            exportExcel(res.data)
            setLoading(false)
        }
    }

    const exportExcel = (data) => {
        var wb = XLSX.utils.book_new()

        const summary_header = [
            'From: ',
            startDate.format(dateFormat),
            'To: ',
            endDate.format(dateFormat),
        ]
        const summary_ws = XLSX.utils.json_to_sheet(
            utils.excel.generateDataList(summary_header),
            utils.excel.generateDataHeader(summary_header, 'A'),
        )

        /* Creating summary excel sheet */ 
        const summary = data.summary
        let current = 2
        let arr = [ 'Age range' ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = [ '18-', '19-30', '31-75', '76+' ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const agerange = summary.age_range
        arr = [ agerange.under18,
            agerange.under30,
            agerange.under75,
            agerange.above75,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2

        arr = ['Case status']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = [ 'Seen', 'Cancelled', 'Others' ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const casestatus = summary.case_status_count
        arr = [ casestatus.seen,
            casestatus.cancel,
            casestatus.others ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2

        arr = ['Gender']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = [ 'Male', 'Female' ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const gender = summary.gender_count
        arr = [
            gender.male,
            gender.female,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2

        arr = ['Refer From']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = [ 'POM meeting', 'TMH NPAC', 'POH NPAC', 'Physio', 'Surgery School', 'CPEX', 'OPNC', 'POMC', 'Others']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const refer_from = summary.refer_from_count
        arr = [
            refer_from.anae,
            refer_from.npac,
            refer_from.pohnpac,
            refer_from.physio,
            refer_from.surgeryschool,
            refer_from.cpex,
            refer_from.ornc,
            refer_from.arac,
            refer_from.others,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2

        arr = ['Survey Average']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = [ 'Q1', 'Q2', 'Q3' ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const average = summary.survey_average
        arr = [
            average.question1,
            average.question2,
            average.question3,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2

        arr = ['Survey Total']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = ['', 'Highly Agree', 'Agree', 'Normal', 'Disagree', 'Highly Disagree']
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const q1_total = summary.survey_total.question1
        arr = [
            'Question 1',
            q1_total.hagree_count,
            q1_total.agree_count,
            q1_total.normal_count,
            q1_total.disagree_count,
            q1_total.hdisagree_count,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const q2_total = summary.survey_total.question2
        arr = [
            'Question 2',
            q2_total.hagree_count,
            q2_total.agree_count,
            q2_total.normal_count,
            q2_total.disagree_count,
            q2_total.hdisagree_count,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const q3_total = summary.survey_total.question3
        arr = [
            'Question 3',
            q3_total.hagree_count,
            q3_total.agree_count,
            q3_total.normal_count,
            q3_total.disagree_count,
            q3_total.hdisagree_count,
        ]
        XLSX.utils.sheet_add_json(summary_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))

        /* Finished creating summary excel sheet */ 

        XLSX.utils.book_append_sheet(wb, summary_ws, 'Summary')

        /* Creating Group Education excel sheet */ 
         const eduation_header = [
            'From: ',
            startDate.format(dateFormat),
            'To: ',
            endDate.format(dateFormat),
        ]
        const education_ws = XLSX.utils.json_to_sheet(
            utils.excel.generateDataList(eduation_header),
            utils.excel.generateDataHeader(eduation_header, 'A'),
        )
        current = 2
        const educations = data.educations.data

        const virtualSchoolData = educations.filter(s => s.source === utils.constants.room_identifier.surgery_school)
        const npacData = educations.filter(s => s.source === utils.constants.room_identifier.npac)

        arr = ['No of Group Education Patient Attendance Summary']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = ['Specialty', 'Patient Attendance', 'Relative Attendance', 'Total']
        //arr = ['Specialty', 'Patient Attendance']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        const specialtyAttendance = virtualSchoolData.filter(s => s.type === 'Speciality')
        specialtyAttendance.sort((a, b) => {
            if (a.order === b.order ){
                if (a.value_type === 'patient') return -1
                return 1
            }
           
            return a.order - b.order
        })

        let patientTotal = 0
        let relativeTotal = 0
        for (let i = 0; i < specialtyAttendance.length; i += 2) 
        {
            patientTotal += specialtyAttendance[i].number
            relativeTotal += specialtyAttendance[i + 1].number
            arr = [
                specialtyAttendance[i].name,
                specialtyAttendance[i].number,
                specialtyAttendance[i + 1].number,
                specialtyAttendance[i].number + specialtyAttendance[i + 1].number,
            ]
            XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
            current++
        }
        //arr = ['Total', patientTotal, relativeTotal]
        arr = ['Total', patientTotal, relativeTotal, patientTotal + relativeTotal]
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2
        

        arr = ['Consultation By PeriOp Nurse :']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++
        let fieldName = []
        let fieldValue = []
        const periOpEducation = virtualSchoolData.filter(s => s.type === 'PeriOp')
        periOpEducation.sort((a, b) => {
            return a.order - b.order
        })
        periOpEducation.forEach(s => {
            fieldName.push(s.name)
            fieldValue.push(s.number)
        })
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(fieldName, 'A'), utils.excel.generateDataInfo(fieldName, 'A', current))
        current++
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(fieldValue, 'A'), utils.excel.generateDataInfo(fieldValue, 'A', current))
        current += 2

        arr = ['Consultation By Physiotherapist :']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++
        fieldName = []
        fieldValue = []
        const physioEducation = virtualSchoolData.filter(s => s.type === 'Physio')
        physioEducation.sort((a, b) => {
            return a.order - b.order
        })
        physioEducation.forEach(s => {
            fieldName.push(s.name)
            fieldValue.push(s.number)
        })
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(fieldName, 'A'), utils.excel.generateDataInfo(fieldName, 'A', current))
        current++
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(fieldValue, 'A'), utils.excel.generateDataInfo(fieldValue, 'A', current))
        current += 2
        
        arr = ['Online NPAC']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++
        const onlineNPAC = educations.find(s => s.name === 'onlineNPAC')
        arr = [
            onlineNPAC.number,
        ]
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 3

        // npac patient attendance summary 
        arr = ['No of NPAC Patient Attendance Summary']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++

        arr = ['Specialty', 'Patient Attendance']
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current++
        
        const npacAttendance = npacData.filter(s => s.type === 'Speciality')
        npacAttendance.sort((a, b) => {
            if (a.order === b.order ){
                if (a.value_type === 'patient') return -1
                return 1
            }
           
            return a.order - b.order
        })

        patientTotal = 0
        //let relativeTotal = 0
        for (let i = 0; i < npacAttendance.length; i ++) 
        {
            patientTotal += npacAttendance[i].number
            //relativeTotal += npacAttendance[i + 1].number
            arr = [
                npacAttendance[i].name,
                npacAttendance[i].number,
                //npacAttendance[i + 1].number,
                //npacAttendance[i].number + specialtyAttendance[i + 1].number,
            ]
            XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
            current++
        }
        arr = ['Total', patientTotal]
        //arr = ['Total', patientTotal, relativeTotal, patientTotal + relativeTotal]
        XLSX.utils.sheet_add_json(education_ws, utils.excel.generateDataList(arr, 'A'), utils.excel.generateDataInfo(arr, 'A', current))
        current += 2
       /* Finished creating Group Education excel sheet */

       XLSX.utils.book_append_sheet(wb, education_ws, 'No of Patient Attendance')
        
       const booking_header = [
            '#',
            'Name',
            'Gender',
            'DOB',
            'Appointment Date',
            'Appointment Time',
            'Booking Clinic',
            'Refer From',
            'Status',
            'Vital Instruction',
            'Vital Monitoring',
        ]
        const bookings_ws = XLSX.utils.json_to_sheet(
            utils.excel.generateDataList(booking_header),
            utils.excel.generateDataHeader(booking_header, 'A'),
        )

        const vitalTemplate = data.template

        current = 2
        data.bookings.forEach(booking => {
            const currentInstruction = []
            if (booking.vitalInstruction) {
                for (let i = 0; i < booking.vitalInstruction.length; i++) {
                    const instruction = booking.vitalInstruction[i]
                    const currentTemplate = vitalTemplate.find(t => t.id === instruction.templateId)
                    if (currentTemplate) {
                        let englishContent = currentTemplate.englishContent
                        englishContent = englishContent.replace(input1Regex, instruction.value1)
                        englishContent = englishContent.replace(input2Regex, instruction.value2)
                        englishContent = englishContent.replace(input3Regex, instruction.value3)
                        englishContent = englishContent.replace(input4Regex, instruction.value4)
                        let currentVital = instruction.vital
                        const currentVitalObj = vitalList.find(v => v.key === currentVital)
                        let vitalName = currentVital
                        if (currentVitalObj) {
                            vitalName = currentVitalObj.name
                        }
                        const finalContent = `${vitalName}: ${englishContent}`
                        currentInstruction.push(finalContent)
                    }
                }
            }

            const monitoring = []
            if (booking.vitalMonitoring) {
                for (let i = 0; i < booking.vitalMonitoring.length; i++) {
                    const currentVital = booking.vitalMonitoring[i]
                    const currentVitalObj = vitalList.find(v => v.key === currentVital)
                    if (currentVitalObj) {
                        monitoring.push(currentVitalObj.name)
                    } else {
                        monitoring.push(currentVital)
                    }
                }
            }

            const arr = [
                current - 1,
                booking.name,
                booking.gender,
                booking.dob,
                dayjs(booking.time).format('YYYY-MM-DD'),
                dayjs(booking.time).format('HH:mm'),
                booking.room.name,
                getReferFormName(booking.referFrom),
                booking.status,
                currentInstruction.join(', '),
                monitoring.join(', '),
            ]
            XLSX.utils.sheet_add_json(
                bookings_ws,
                utils.excel.generateDataList(arr, 'A'),
                utils.excel.generateDataInfo(arr, 'A', current),
            )
            current++
        })

        XLSX.utils.book_append_sheet(wb, bookings_ws, 'Bookings')
        XLSX.writeFile(wb, 'summary' + fileExtension)
    }

    const getReferFormName = (input) => {
        if (input === 'anae') return 'POM meeting'
        if (input === 'npac') return 'TMH NPAC'
        if (input === 'pohnpac') return 'POH NPAC'
        if (input === 'physio') return 'Physio'
        if (input === 'surgeryschool') return 'Surgery School'
        if (input === 'cpex') return 'CPEX'
        if (input === 'ornc') return 'OPNC'
        if (input === 'arac') return 'POMC'
        return 'Others'
    }

    return (
        <>
            <Card variant="outlined">
            <Toolbar>
            <Grid container alignItems="center">
                <Grid item xs>
                <Typography variant="h6">Summary Report</Typography>
                </Grid>
            </Grid>
            </Toolbar>
            <Divider />
            {loading && <LinearProgress />}
                <Box p={3}>
                    <Grid container alignItems="center" justifyContent="space-around" spacing={5}>
                        <MuiPickersUtilsProvider utils={DateDayjsUtils}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                fullWidth
                                margin="normal"
                                label="Start Date"
                                format="YYYY-MM-DD"
                                value={startDate}
                                onChange={onStartDateChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                fullWidth
                                margin="normal"
                                label="End Date"
                                format="YYYY-MM-DD"
                                value={endDate}
                                onChange={onEndDateChange}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={revisitPatientOnly}
                                        onChange={(e) => {
                                            setRevisitPatientOnly(e.target.checked)
                                        }}
                                        name="revisitPatientOnly"
                                    />
                                }
                                label="Revisit Patient Only"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box p={3}>
                    <Grid container spacing={3}>
                        <Button 
                            color="primary"
                            variant="outlined"
                            fullWidth
                            onClick={fetchSummary}
                        >FETCH</Button>
                    </Grid>
                </Box>
                <Box p={3}></Box>
            </Card> 
        </>
    )
}

export default connect(state => ({
    roles: state.user.roles,
}))(AdminSummary)