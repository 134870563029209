import React from 'react'
import {
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Button,
  Typography,
} from '@material-ui/core'

import utils from '../../utils'

const inputFieldStyle = makeStyles(() => ({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    inlineSpan: ({ color }) => ({
      display: 'inline',
      background: color,
    }),
  }))


const useStyles = makeStyles((theme) => ({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
    margin: {
      margin: theme.spacing(3),
    },
    inline: {
      display: 'inline',
    },
  }))


function BookingVitalsDialog(props) {
    const {
        templateList,
        vitalList,
        editingVital,
        updateVitalTemplate,
        updateVitalField,
        updateVitalValue,
        confirmVital,
        cancelVital,
        roomColor,
    } = props

    const classes = useStyles({ color: roomColor })

    const blueClass = inputFieldStyle({ color: 'blue'})
    const orangeClass = inputFieldStyle({ color: 'orange'})
    const greenClass = inputFieldStyle({ color: 'green'})
    const redClass = inputFieldStyle({ color: 'red'})

    const checkingRegex = new RegExp(utils.getInputFieldRegez(), 'g')

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={2} lg={2}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Template"
                        className={classes.textField}
                        defaultValue={editingVital.selectedTemplate}
                        onChange={e => updateVitalTemplate(e)}
                    >
                        {templateList.map((template, j) => {
                            if (template.deleted) {
                                return false
                            }

                            return (
                                <MenuItem key={'template' + template.id} value={template.id}>{template.label}</MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Vital"
                        className={classes.textField}
                        defaultValue={editingVital.selectedVital}
                        onChange={e => updateVitalField(e)}
                    >
                        { vitalList.map((vitalInfo) => {
                            return (
                                <MenuItem key={'vitalselect' + vitalInfo.key} value={vitalInfo.key}>{vitalInfo.name}</MenuItem>
                            )
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Value1"
                        className={blueClass.textField}
                        defaultValue={editingVital.values[0]}
                        onChange={e => updateVitalValue(e, 0)}
                    />
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Value2"
                        className={orangeClass.textField}
                        defaultValue={editingVital.values[1]}
                        onChange={e => updateVitalValue(e, 1)}
                    />
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Value3"
                        className={greenClass.textField}
                        defaultValue={editingVital.values[2]}
                        onChange={e => updateVitalValue(e, 2)}
                    />
                </Grid>
                <Grid item xs={3} md={2} lg={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Value4"
                        className={redClass.textField}
                        defaultValue={editingVital.values[3]}
                        onChange={e => updateVitalValue(e, 3)}
                    />
                </Grid>
                <Grid
                    container
                    spacing={3}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    xs={12} md={12} lg={12}
                >  
                    { editingVital.chineseContent.length !== 0 && 
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h4" component="h4">
                                {editingVital.chineseContent.map((content, x) => {
                                    if (checkingRegex.test(content)) {
                                        let currentIndex = parseInt(content.replace(/\D/g,'')) - 1
                                        let currentClass = blueClass.inlineSpan
                                        switch (currentIndex) {
                                            case 1:
                                              currentClass = orangeClass.inlineSpan
                                              break
                                            case 2:
                                              currentClass = greenClass.inlineSpan
                                              break
                                            case 3:
                                              currentClass = redClass.inlineSpan
                                              break
                                            default:
                                              currentClass = blueClass.inlineSpan
                                              break
                                        }

                                        return (
                                            <span className={currentClass}>[{editingVital.values[currentIndex]}]</span>
                                        )
                                    }
                                    return (
                                        <span className={classes.inline}>{content}</span>
                                    )
                                })}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    spacing={3}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    xs={12} md={12} lg={12}
                >  
                    { editingVital.englishContent.length !== 0 && 
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h4" component="h4">
                                {editingVital.englishContent.map((content, x) => {
                                    if (checkingRegex.test(content)) {
                                        let currentIndex = parseInt(content.replace(/\D/g,'')) - 1
                                        let currentClass = blueClass.inlineSpan
                                        switch (currentIndex) {
                                            case 1:
                                              currentClass = orangeClass.inlineSpan
                                              break
                                            case 2:
                                              currentClass = greenClass.inlineSpan
                                              break
                                            case 3:
                                              currentClass = redClass.inlineSpan
                                              break
                                            default:
                                              currentClass = blueClass.inlineSpan
                                              break
                                        }

                                        return (
                                            <span className={currentClass}>[{editingVital.values[currentIndex]}]</span>
                                        )
                                    }
                                    return (
                                        <span className={classes.inline}>{content}</span>
                                    )
                                })}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Button
                                color="secondary"
                                margin="normal"
                                fullWidth
                                variant="contained"
                                onClick={cancelVital}
                            >Cancel</Button>
                        </Grid>
                        <Grid item xs>
                            <Button 
                                color="primary"
                                textColor="white"
                                margin="normal"
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={confirmVital}
                            >Confirm</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BookingVitalsDialog