export const login = (id, username, name, roles) => {
  return {
    type: 'UPDATE_USER_INFO',
    id,
    username,
    name,
    roles,
  }
}

export const logout = () => {
  return {
    type: 'LOGOUT',
  }
}

export const updateRoom = (id, name, key, interval, color, surveyLink) => {
  return {
    type: 'SET_ROOM',
    id,
    name,
    key,
    interval,
    color,
    surveyLink,
  }
}

export const clearRoomState = () => {
  return {
    type: 'SET_ROOM',
    id: '',
    name: '',
    key: '',
    interval: 0,
    color: '',
    surveyLink: '',
  }
}

export const updatePatientInfo = (
  id, sopd, name, gender, dob, time, code, status,
) => {
  return {
    type: 'SET_PATIENT_STATE',
    id, sopd, name, gender, dob, time, code, status,
  }
}

const actions = {
  login,
  logout,
  updateRoom,
  updatePatientInfo,
  clearRoomState,
}

export default actions