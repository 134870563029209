const backendURL = () => {
    if (process.env.REACT_APP_ENV === 'development') {
        return process.env.REACT_APP_BACKEND_API_HOST
    }
    return `${window.location.origin}/api`
}

const socketURL = (roomKey) => {
    if (process.env.REACT_APP_ENV === 'development') {
        const url = `${process.env.REACT_APP_WEBSOCKET_HOST}/${roomKey}`
        return url
    }
    const isHttps = window.location.protocol === 'https:'
    const socketprotocol = isHttps ? 'wss' : 'ws'
    const url = `${socketprotocol}://${window.location.hostname}/api/ws/${roomKey}`
    return url
}

const location = {
    backendURL,
    socketURL,
}

export default location