import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'

import routes from '../../api/routes'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

export default function VitalRecords(props) {
  const { hn } = props
  const init = useRef(false)
  const [records, setRecords] = useState([])
  const [displayVitals, setDisplayVitals] = useState([])

  const refresh = async () => {
    try {
      const { data } = await routes.observations.list(hn)
      setRecords(data)
    } catch (err) {
      console.log(err)
      alert('Error occurs when fetching observation list')
    }
  }

  const fetchVitals = async () => {
    try {
      const { data } = await routes.observations.vitals(hn)
      let display = []
      data.forEach((info) => {
        display.push(info.vital)
      })
      setDisplayVitals(display)
    } catch (err) {
      console.log(err)
      alert('Error occurs when fetching display vital information')
    }
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
      refresh()
      fetchVitals()
    }
  })

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Time</StyledTableCell>
            {displayVitals.includes('sysdia') && <StyledTableCell align="center">SYS/DIA</StyledTableCell>}
            {displayVitals.includes('sys') && <StyledTableCell align="center">SYS</StyledTableCell>}
            {displayVitals.includes('dia') && <StyledTableCell align="center">DIA</StyledTableCell>}
            {displayVitals.includes('temperature') && <StyledTableCell align="center">Temperature</StyledTableCell>}
            {displayVitals.includes('spo2') && <StyledTableCell align="center">SpO2</StyledTableCell>}
            {displayVitals.includes('hstix') && <StyledTableCell align="center">H'stix</StyledTableCell>}
            {displayVitals.includes('bodyweight') && <StyledTableCell align="center">BodyWeight</StyledTableCell>}
            {displayVitals.includes('imt') && <StyledTableCell align="center">IMT</StyledTableCell>}
            {displayVitals.includes('cigarette') && <StyledTableCell align="center">Cigarette</StyledTableCell>}
            {displayVitals.includes('aerobic') && <StyledTableCell align="center">Aerobic</StyledTableCell>}
            <StyledTableCell align="left">Remark</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map(record => (
            <StyledTableRow key={record.id}>
              <TableCell align="center">{dayjs(record.at).format('YYYY-MM-DD')}</TableCell>
              <TableCell align="center">{dayjs(record.at).format('HH:mm:ss')}</TableCell>
              {displayVitals.includes('sysdia') && <TableCell align="center">{!!record.sysdia ? record.sysdia : '--'}</TableCell>}
              {displayVitals.includes('sys') && <TableCell align="center">{!!record.sys ? record.sys : '--'}</TableCell>}
              {displayVitals.includes('dia') && <TableCell align="center">{!!record.dia ? record.dia : '--'}</TableCell>}
              {displayVitals.includes('temperature') && <TableCell align="center">{!!record.temperature ? record.temperature : '--'}</TableCell>}
              {displayVitals.includes('spo2') && <TableCell align="center">{!!record.spo2 ? record.spo2 : '--'}</TableCell>}
              {displayVitals.includes('hstix') && <TableCell align="center">{!!record.hstix ? record.hstix : '--'}</TableCell>}
              {displayVitals.includes('bodyweight') && <TableCell align="center">{!!record.bodyWeight ? record.bodyWeight : '--'}</TableCell>}
              {displayVitals.includes('imt') && <TableCell align="center">{!!record.imt ? record.imt : '--'}</TableCell>}
              {displayVitals.includes('cigarette') && <TableCell align="center">{!!record.cigarette ? record.cigarette : '--'}</TableCell>}
              {displayVitals.includes('aerobic') && <TableCell align="center">{!!record.aerobic ? record.aerobic : '--'}</TableCell>}
              <TableCell align="left">{record.remark}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}