import getAxios from './axios'

/**
 * Get the vital record list by the hospital number
 * @param {string} hn Patients hospital number
 * @returns
 */
const list = (hn) => getAxios({
  url: '/observations',
  method: 'GET',
  params: {
    hn,
  },
})

const vitals = (hn) => getAxios({
  url: '/displayvitals',
  method: 'GET',
  params: {
    hn,
  },
})

const rooms = {
  list,
  vitals,
}

export default rooms