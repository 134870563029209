const generateDataList = (arr, originStart = 'A') => {
    let currentAscii = originStart.charCodeAt(0)
    let output = {}
    arr.forEach(element => {
        output[String.fromCharCode(currentAscii)] = element
        currentAscii++
    })
    return [output]
}

const generateDataInfo = (arr, originStart = 'A', originRow = 1) => {
    let headerArr = []
    let currentAscii = originStart.charCodeAt(0)
    for (let i = 0; i < arr.length; i++) {
        headerArr.push(String.fromCharCode(currentAscii))
        currentAscii++
    }

    return {
        headers: headerArr,
        skipHeader: true,
        origin: originStart + originRow,
    }
}

const generateDataHeader = (arr, originStart = 'A') => {
    let headerArr = []
    let currentAscii = originStart.charCodeAt(0)
    for (let i = 0; i < arr.length; i++) {
        headerArr.push(String.fromCharCode(currentAscii))
        currentAscii++
    }
    return {
        headers: headerArr,
        skipHeader: true,
    }
}

const excel = {
    generateDataInfo,
    generateDataList,
    generateDataHeader,
}

export default excel