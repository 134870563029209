import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import ColoredButton from '../../components/ColoredButton'
import { updateRoom } from '../../store/actions'
import api from '../../api'

function RoomList({ rooms, onRoomClick }) {
  if (!rooms) return false
  if (!rooms.length) return false

  return rooms.map(room => {
    if (!room.activated) return false

    return (
      <Grid item xs={12} key={room.key}>
        <ColoredButton
          color={room.color}
          textColor="white"
          variant="contained"
          fullWidth
          onClick={() => { onRoomClick(room) }}
        >
          <Typography variant="h2">{room.name}</Typography>
        </ColoredButton>
      </Grid>
    )
  })
}

function AdminHome({ user, dispatch }) {
  const history = useHistory()
  const init = useRef(false)
  const [rooms, setRooms] = useState([])
  const [error, setError] = useState(false)

  if (error) {
    // TODO error handling
  }

  function selectRoom({ id, name, key, interval, color }) {
    dispatch(updateRoom(id, name, key, interval, color))
    history.push('/admin/room/')
  }

  useEffect(() => {
    if (!init.current) {
      api.routes.users.getRooms(user.id).then(({ data }) => {
        setRooms(data)
      }).catch(err => {
        setError(err)
      }).finally(() => {
        init.current = true
      })
    }
  })

  return (
    <Grid container spacing={3}>
      <RoomList
        rooms={rooms}
        onRoomClick={(room) => { selectRoom(room) }}
      ></RoomList>
    </Grid>
  )
}

export default connect(state => ({
  user: state.user,
  roomName: state.room.name,
}))(AdminHome)