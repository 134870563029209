import getAxios from './axios'

const list = () => getAxios({
    url: '/survey',
    method: 'GET',
})

const get = (code) => getAxios({
    url: `/patients/${code}/survey`,
    method: 'GET',
})

const create = (code, content) => {
    const data = {
        content,
    }

    return getAxios({
        url: `/patients/${code}/survey`,
        method: 'POST',
        data,
    })
}

const remove = (surveyId) => getAxios({
    url: `/survey/${surveyId}`,
    method: 'DELETE',
})

const surveys = {
    list, 
    get,
    create,
    delete: remove,
}

export default surveys