import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Snackbar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Divider,
  Dialog,
  Slide,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import RefreshIcon from '@material-ui/icons/Refresh'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import UserForm from '../../components/UserManagement/UserForm'

import api from '../../api'
import utils from '../../utils'
import { useEffect } from 'react'

function ListedTableBody(props) {
  const {
    list,
    onEditClick,
    onDeleteClick,
  } = props
  let rows = []

  if (list && Array.isArray(list)) {
    rows = list.map(item => {
      const roles = item.roles.map(role => role.toUpperCase()).sort().join(', ')
      const activated = item.activated ? 'primary' : 'disabled'

      return (
        <TableRow key={item.username}>
          <TableCell>{item.username}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{roles}</TableCell>
          <TableCell align="center">
            <EmojiObjectsIcon color={activated} />
          </TableCell>
          <TableCell align="center">
            <IconButton color="primary" onClick={() => onEditClick(item.id)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => onDeleteClick(item.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }

  return <TableBody>{rows}</TableBody>
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    mountOnEnter
    unmountOnExit
    {...props}
  />
})

function AdminUserManagement(props) {
  const history = useHistory()
  const {
    roles,
  } = props
  const initFetch = useRef(false)
  const [userList, setUserList] = useState([])
  const [userListErr, setUserListErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [showForm, setShowForm] = useState(false)

  function createUserHandler() {
    setSelectedUserId(null)
    setShowForm(true)
  }

  function onFormCloseHandler() {
    setSelectedUserId(null)
    setShowForm(false)
  }

  function onEditClickHandler(userId) {
    setSelectedUserId(userId)
    setShowForm(true)
  }

  function onDeleteClickHandler(userId) {
    const user = userList.find(u => u.id === userId)

    if (!user) {
      return
    }

    if (window.confirm(`Confirm to delete ${user.name} account?`)) {
      api.routes.users.destroy(userId).then(() => {
        refresh()
      }).catch(e => {
        alert(e.response.status, e.response.data)
      })
    }
  }

  function onUserChangeHandler() {
    setShowForm(false)
    refresh()
  }

  async function refresh() {
    setLoading(true)
    setUserListErr(false)
    try {
      const { data } = await api.routes.users.list()
      setUserList(data)
    } catch (err) {
      setUserListErr(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!initFetch.current) {
      initFetch.current = true
      refresh()
    }
  })

  if (!utils.isSuper(roles) && !utils.isAdmin(roles)) {
    history.replace('/admin')
    return false
  }

  return (
    <>
      <Card variant="outlined">
        {userListErr &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={true}
          >
            <MuiAlert
              variant="filled"
              severity="error"
            >Error occurs when loading user list</MuiAlert>
          </Snackbar>
        }
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">User Management</Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={refresh}>
                <RefreshIcon />
              </IconButton>
              <IconButton color="primary" onClick={createUserHandler}>
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        {loading && <LinearProgress />}
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell align="center">Activated</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <ListedTableBody
              list={userList}
              onEditClick={onEditClickHandler}
              onDeleteClick={onDeleteClickHandler}
            />
          </Table>
        </TableContainer>
      </Card>
      <Dialog
        fullScreen
        open={showForm}
        onClose={onFormCloseHandler}
        TransitionComponent={Transition}
      >
        {showForm &&
          <UserForm
            userId={selectedUserId}
            onCloseClick={() => setShowForm(false)}
            onUserCreated={onUserChangeHandler}
            onUserUpdated={onUserChangeHandler}
          />
        }
      </Dialog>
    </>
  )
}

export default connect(state => ({
  roles: state.user.roles,
}))(AdminUserManagement)