import React, { useState, useRef, useEffect } from 'react'
import {
    Grid,
    Box,
    IconButton,
} from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


function LeafletDisplay(props) {
    const gridRef = useRef()
    const [canvasWidth, setWidth] = useState(1)
    
    const [currentPage, setCurrentPage] = useState(1)
    const totalPage = useRef(0)

    useEffect(() => {
        setCurrentPage(1)
    }, [props.path])

    useEffect(() => {
        if (gridRef.current) {
            const { current } = gridRef
            const boundingRect = current.getBoundingClientRect()
            const { width } = boundingRect
            setWidth(width)
        }
    }, [gridRef])

    function changePage(change) {
        if (currentPage + change === 0) return
        if (currentPage + change === totalPage.current + 1) return
        setCurrentPage(page => page + change)
    }

    function onDocumentLoadSuccess(e) {
        totalPage.current = e.numPages
    }

    return (
        <Grid container alignItems="center" ref={gridRef}>
            <Grid item style={{ position: 'relative' }}>
                <Document
                    file={{
                        url: props.path,
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                    loading="Loading......"
                    error="Failed to load leftlet"
                >
                    <Page
                        pageNumber={currentPage}
                        scale={1}
                        width={canvasWidth}
                    />
                </Document>
                <Grid item style={{ position: 'absolute', width: '100%', top: '50%' }} xs={12} lg={12} md={12}>
                    <Grid container xs={12} lg={12} md={12}>
                        <Grid item xs>
                            <IconButton
                                disabled={currentPage === 1}
                                size="small"
                                onClick={() => changePage(-1)}>
                                    <ArrowBackIosIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item xs>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    disabled={currentPage === totalPage.current}
                                    size="small"
                                    onClick={() => changePage(1)}>
                                    <ArrowForwardIosIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}   

export default LeafletDisplay