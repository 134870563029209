import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core'

import api from '../../../api'

import ColoredButton from '../../ColoredButton'
import { connect } from 'react-redux'

const useStyles = makeStyles({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
  })

  function ReasonForm(props) {
      const { onReasonCreated, onReasonUpdated, onCancelClick, user } = props
      const classes = useStyles({ color: 'green' })
      const { handleSubmit, errors, control } = useForm({
        reValidateMode: 'onSubmit',
      })
      const [reason, setReason] = useState(props.cancelReason || {
          content: '',
      })

      const [updatingReasons, setUpdatingReasons] = useState(false)
      const isUpdate = !!props.cancelReason

      async function onSubmitHandler(data) {
        setUpdatingReasons(true)

        if (!data.content) {
            setUpdatingReasons(false)
            alert('Content is empty, please check.')
            return
        }

        try {
            if (isUpdate) {
                const reason = await api.routes.cancelReasons.update(
                    props.cancelReason.id,
                    user.id,
                    user.name,
                    data.content,
                )

                onReasonUpdated && onReasonUpdated(reason.data)
            } else {
                const reason = await api.routes.cancelReasons.create(
                    user.id,
                    user.name,
                    data.content,
                )

                onReasonCreated && onReasonCreated(reason.data)
            }
        } catch (err) {
          console.log(err)
            alert('Error occurs when saving the reasons')
        } finally {
            setUpdatingReasons(false)
        }
      }

      return (
          <>
            <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="content"
                            defaultValue={reason.content}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Content"
                                    className={classes.textField}
                                    error={!!errors.content}
                                    disabled={updatingReasons}
                                ></TextField>
                            }
                        ></Controller>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button
                                    color="secondary"
                                    margin="normal"
                                    fullWidth
                                    variant="contained"
                                    onClick={onCancelClick}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs>
                                <ColoredButton
                                    color={'green'}
                                    textColor="white"
                                    margin="normal"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={updatingReasons}
                                >{!!props.cancelReason ? 'Update' : 'Create'}</ColoredButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
          </>
      )
  }

  export default connect(state => ({
    user: state.user,
  }))(ReasonForm)