import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import api from '../api'
import actions from '../store/actions'
import ChatRoom from '../components/Room/ChatRoom'
import useStream from '../hooks/useStream'
import useWebSocket from '../hooks/useWebSocket'

function Room(props) {
  const history = useHistory()
  const { room, patient, dispatch } = props
  const socketClosePage = useRef(`/?code=${patient.code}`)
  const {
    disconnectWebSocket,
    subscribeOnError,
    unsubscribeOnError,
    subscribeOnClose,
    unsubscribeOnClose,
    subscribeOnOpen,
    unsubscribeOnOpen,
    sendWebsocketMessage,
  } = useWebSocket()
  const {
    closeStream,
  } = useStream()

  useEffect(() => {
    subscribeOnOpen('patientRoom', onSocketOpenedHandler)
    subscribeOnError('patientRoom', onSocketErrorHandler)
    subscribeOnClose('patientRoom', onSocketCloseHandler)

    return () => {
      unsubscribeOnOpen('patientRoom')
      unsubscribeOnError('patientRoom')
      unsubscribeOnClose('patientRoom')
    }
  })


  if (!room.name) {
    history.push('/')
  }

  function onSocketErrorHandler() {
    closeStream()
    disconnectWebSocket()
    dispatch(actions.logout())
    history.push(socketClosePage.current)
  }

  function onSocketCloseHandler() {
    closeStream()
    disconnectWebSocket()
    dispatch(actions.logout())
    history.push(socketClosePage.current)
  }

  function onSocketOpenedHandler() {
    sendWebsocketMessage(api.ws.newGetIceServersConfig())
  }

  function setSocketClosePage(to = '/') {
    socketClosePage.current = to
  }

  return (
    <ChatRoom
      patient={patient}
      room={room}
      sender={patient.code}
      setSocketClosePage={setSocketClosePage}
    ></ChatRoom>
  )
}

export default connect(state => ({
  patient: state.patient,
  room: state.room,
}))(Room)