import React from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableContainer,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import LinkIcon from '@material-ui/icons/Link'
import EditIcon from '@material-ui/icons/Edit'
import ViewListIcon from '@material-ui/icons/ViewList'

import BookingStatus from './BookingStatus'

import utils from '../../../utils'
import { testingClinicKey } from '../../../libs/constants'

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
})

function BookingTable(props) {
  const {
    room,
    bookings,
    onDeleteClick,
    onBookingLinkClick,
    onBookingEditClick,
    onVitalRecordsClick,
    showDate,
    highlightDate,
    roles,
  } = props
  const classes = useStyles()
  //const picked = highlightDate ? dayjs(highlightDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
  if (!bookings) return false

  const isTestingClinic = room.key === testingClinicKey

  const rows = bookings.map((booking, index) => {
    return (
      //<TableRow key={booking.code} selected={highlightDate ? picked === dayjs(booking.time).format('YYYY-MM-DD') : false}>
      <TableRow key={`booking-${index}`}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{booking.name}</TableCell>
        <TableCell>{booking.sopd}</TableCell>
        { showDate &&
          <TableCell align="center">{dayjs(booking.time).format('YYYY-MM-DD')}</TableCell>
        }
        <TableCell align="center">{dayjs(booking.time).format('HH:mm')}</TableCell>
        { booking.otDate ? (
          <TableCell align="center">{dayjs(booking.otDate).format('YYYY-MM-DD')}</TableCell>
        ) : (
          <TableCell align="center">-</TableCell>
        )}
        <TableCell align="center">
          <BookingStatus booking={booking} />
        </TableCell>
        <TableCell align="center">{booking.creator ? booking.creator.name : ''}</TableCell>
        <TableCell align="center">
          {!isTestingClinic && (utils.isSuper(roles) || utils.isNormal(roles)) &&
            <IconButton onClick={() => onBookingEditClick(booking)} color="primary" size="small">
              <EditIcon />
            </IconButton>
          }
          <IconButton onClick={() => onBookingLinkClick(booking)} color="primary" size="small">
            <LinkIcon />
          </IconButton>
          { booking.vitalInstruction.length === 0 ? (
            <IconButton onClick={() => onVitalRecordsClick(booking)} color="primary" size="small">
              <ViewListIcon />
            </IconButton>
            ) : (
              <IconButton onClick={() => onVitalRecordsClick(booking)} style={{ color: '#78ff63' }} size="small">
                <ViewListIcon />
              </IconButton>
            )
          }
          
          {!isTestingClinic && (utils.isSuper(roles) || utils.isNormal(roles)) &&
            <IconButton onClick={() => onDeleteClick(booking)} color="secondary" size="small">
              <DeleteIcon />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    )
  })

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>SOPD/HN</TableCell>
            {showDate &&
              <TableCell align="center">Date</TableCell>
            }
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">OT Date</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Creator</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default connect(state => ({
  roles: state.user.roles,
}))(BookingTable)