import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  makeStyles,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core'

import api from '../../../api'

import ColoredButton from '../../ColoredButton'
import { connect } from 'react-redux'

const useStyles = makeStyles({
    textField: ({ color }) => ({
      '& label.Mui-focused': {
        color: color,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: color,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: color,
        },
        '&:hover fieldset': {
          borderColor: color,
        },
        '&.Mui-focused fieldset': {
          borderColor: color,
        },
      },
    }),
    select: ({ color }) => ({
      borderColor: color,
      '&:focus': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
      },
      '& label.Mui-focused': {
        color: color,
      },
    }),
  })

  function ReasonForm(props) {
    const { onReasonCreated, onReasonUpdated, onCancelClick, user } = props
    const classes = useStyles({ color: 'purple' })
    const { handleSubmit, errors, control } = useForm({
        reValidateMode: 'onSubmit',
    })
    const [reason, setReason] = useState(props.consultationReason || {
        content: '',
        reason_type: '',
    })
    const [updatingReasons, setUpdatingReasons] = useState(false)
    const isUpdate = !!props.consultationReason

    async function onSubmitHandler(data) {
        setUpdatingReasons(true)

        if (!data.content) {
            setUpdatingReasons(false)
            alert('Content is empty, please check')
            return
        }

        if (!data.reason_type) {
            setUpdatingReasons(false)
            alert('Reason type is empty, please check')
            return
        }

        if (!data.order_number) {
            setUpdatingReasons(false)
            alert('Order number is empty, please check')
            return
        }

        try {
            if (isUpdate) {
                const reason = await api.routes.consultationReasons.update(
                    props.consultationReason.id,
                    user.id,
                    user.name,
                    data.content,
                    data.reason_type,
                    data.order_number,
                )

                onReasonUpdated && onReasonUpdated(reason.data)
            } else {
                const reason = await api.routes.consultationReasons.create(
                    user.id,
                    user.name,
                    data.content,
                    data.reason_type,
                    data.order_number,
                )

                onReasonCreated && onReasonCreated(reason.data)
            }
        } catch (err) {
            console.log(err)
            alert('Error occurs when saving the reasons')
        } finally {
            setUpdatingReasons(false)
        }
    }

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="content"
                            defaultValue={reason.content}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Content"
                                    className={classes.textField}
                                    error={!!errors.content}
                                    disabled={updatingReasons}
                                ></TextField>
                            }
                        ></Controller>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl margin="normal" fullWidth variant="outlined" className={classes.select}>
                            <InputLabel>Reason Type</InputLabel>
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="reason_type"
                                defaultValue={reason.reason_type}
                                as={
                                    <Select
                                        label="Reason Type"
                                        error={!!errors.reason_type}
                                        disabled={updatingReasons}
                                    >
                                        <MenuItem value={'Speciality'}>Speciality</MenuItem>
                                        <MenuItem value={'PeriOp'}>Peri-Op Nurse</MenuItem>
                                        <MenuItem value={'Physio'}>Physiotherapist</MenuItem>
                                    </Select>
                                }
                            ></Controller>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Controller
                            control={control}
                            rules={{ required: true }}
                            name="order_number"
                            defaultValue={reason.order_number}
                            as={
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Content"
                                    type="number"
                                    className={classes.textField}
                                    error={!!errors.content}
                                    disabled={updatingReasons}
                                ></TextField>
                            }
                        ></Controller>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <Button
                                    color="secondary"
                                    margin="normal"
                                    fullWidth
                                    variant="contained"
                                    onClick={onCancelClick}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs>
                                <ColoredButton
                                    color={'green'}
                                    textColor="white"
                                    margin="normal"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={updatingReasons}
                                >{!!props.consultationReason ? 'Update' : 'Create'}</ColoredButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
  }

  export default connect(state => ({
    user: state.user,
  }))(ReasonForm)