export default function roomReducer(state = {
  id: '',
  name: '',
  key: '',
  interval: 0,
  color: '',
  surveyLink: '',
}, action) {
  switch (action.type) {
    case 'SET_ROOM':
      return {
        id: action.id,
        name: action.name,
        key: action.key,
        interval: action.interval,
        color: action.color,
        surveyLink: action.surveyLink,
      }
    case 'LOGOUT':
      return {
        id: '',
        name: '',
        key: '',
        interval: 0,
        color: '',
        surveyLink: '',
      }
    default:
      return state
  }
}