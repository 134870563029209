import React, { useMemo } from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

function YoutubeMaterial(props) {
  const { material, width } = props
  const url = new URL(material.content)
  const code = url.searchParams.get('v')

  const [vw, vh] = useMemo(() => {
    if (isWidthUp('lg', width)) {
      return [1280, 720]
    } else if (isWidthUp('md', width)) {
      return [960, 540]
    } else if (isWidthUp('sm', width)) {
      return [600, 338]
    } else if (isWidthUp('xs', width)) {
      return [320, 170]
    }
  }, [width])

  return <iframe
    title={material.content}
    width={vw}
    height={vh}
    src={'https://www.youtube.com/embed/' + code}
    frameBorder="0"
    allowFullScreen
  ></iframe>
}

export default withWidth()(YoutubeMaterial)