import getAxios from './axios'

const list = (bookingId) => getAxios({
  url: `/bookings/${bookingId}/remarks`,
  method: 'GET',
})

const create = (bookingId, userId, userName, content) => getAxios({
  url: `/bookings/${bookingId}/remarks`,
  method: 'PUT',
  data: {
    userId,
    userName,
    content,
  },
})

const update = (bookingId, remarkId, userId, userName, content) => getAxios({
  url: `/bookings/${bookingId}/remarks/${remarkId}`,
  method: 'PUT',
  data: {
    userId,
    userName,
    content,
  },
})

const remove = (bookingId, remarkId) => getAxios({
  url: `/bookings/${bookingId}/remarks/${remarkId}`,
  method: 'DELETE',
})

const bookingRemarks = {
  list,
  create,
  update,
  delete: remove,
}

export default bookingRemarks