import { isWidthUp, makeStyles, withWidth } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  root: ({color, size}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: size,
    height: size,
    borderColor: color,
    borderWidth: size * 0.05,
    borderRadius: size,
    borderStyle: 'solid',
    color: color,
    fontWeight: 800,
  }),
  inner: ({size}) => ({
    fontSize: size/ 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: size * 0.8,
    height: size * 0.8,
    textAlign: 'center',
  }),
})

function RoomNameCircle({width, color, children}) {
  let size = 100
  if (isWidthUp('md', width)) {
    size = 150
  }

  const classes = useStyles({ size, color })

  return (
    <div className={classes.root}>
      <span className={classes.inner}>{children}</span>
    </div>
  )
}

export default withWidth()(RoomNameCircle)