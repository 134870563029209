import routesModule from './routes'
import websocketModule from './websocket'

const api = {
  routes: routesModule,
  ws: websocketModule,
}

export const routes = routesModule
export const ws = websocketModule
export default api