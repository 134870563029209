import getAxios from './axios'

const get = (id) => getAxios({
    url: `/consultations/${id}`,
    method: 'GET',
})

const list = () => getAxios({
    url: '/consultations',
    method: 'GET',
})

const create = (userId, userName, content, reasonType, orderNumber) => getAxios({
    url: '/consultations',
    method: 'PUT',
    data: {
        userId,
        userName,
        content,
        type: reasonType,
        orderNumber,
    },
})


const update = (reasonId, userId, userName, content, reasonType, orderNumber) => getAxios({
    url: `/consultations/${reasonId}`,
    method: 'PUT',
    data: {
        userId,
        userName,
        content,
        type: reasonType,
        orderNumber,
    },
})

const remove = (reasonId) => getAxios({
    url: `/consultations/${reasonId}`,
    method: 'DELETE',
})

const consultationReasons = {
    get,
    list,
    create,
    update,
    delete: remove,
}

export default consultationReasons