import getAxios from './axios'

const login = (username, password) => getAxios({
  url: '/auth/login',
  method: 'POST',
  data: {
    username,
    password,
  },
})

const logout = () => getAxios({
  url: '/auth/logout',
  method: 'GET',
})

const me = () => getAxios({
  url: '/auth/me',
  method: 'GET',
})

const auth = {
  login,
  logout,
  me,
}

export default auth