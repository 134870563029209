import getAxios from './axios'

const list = (roomId) => {
  return getAxios({
    url: '/material_groups',
    method: 'GET',
    params: {
      'rid': roomId,
    },
  })
}

const create = (roomId, title) => {
  return getAxios({
    url: '/material_groups',
    method: 'POST',
    data: {
      roomId,
      title,
    },
  })
}

const show = (id) => {
  return getAxios({
    url: `/material_groups/${id}`,
    method: 'GET',
  })
}

const update = (id, title) => {
  return getAxios({
    url: `/material_groups/${id}`,
    method: 'PATCH',
    data: {
      title,
    },
  })
}

const remove = (id) => {
  return getAxios({
    url: `/material_groups/${id}`,
    method: 'DELETE',
  })
}

const materialGroups = {
  list,
  create,
  show,
  update,
  remove,
}

export default materialGroups