import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Dialog,
  Box,
  Card,
  Typography,
  Divider,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  DialogContent,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PrintIcon from '@material-ui/icons/Print'
import LanguageIcon from '@material-ui/icons/Language'

import utils from '../utils'

import CautionList from './CautionList'
import PreparationList from './PreparationList'

export default function PatientQRCodeDialog(props) {
  const {
    booking,
    onClose,
    children,
  } = props

  const { t, i18n } = useTranslation()

  const [patientQR, setPatientQR] = useState(null)
  const [webQR, setWebQE] = useState(null)

  const isTestingClinic = () => {
    return booking.code.length === 7 && booking.code[0] === 'T'
  }

  useEffect(() => {
    if (!webQR) {
      QRCode.toDataURL(document.location.origin, {
        errorCorrectionLevel: 'H',
        width: 200,
      }, (err, url) => {
        setWebQE(url)
      })
    }

    if (!patientQR && booking && booking.code) {
      QRCode.toDataURL(utils.getPatientURL(booking.code), {
        errorCorrectionLevel: 'H',
        width: 150,
      }, (err, url) => {
        setPatientQR(url)
      })
    }
  })

  if (!booking) {
    return false
  }

  return (
    <Dialog open={true} onClose={onClose} className="print-area" scroll="paper" maxWidth="lg">
      <DialogContent>
        {booking &&
          <Card variant="outlined">
            <Box p={2} className="non-print-area">
              <Grid container spacing={2}>
                <Grid item>
                  <CopyToClipboard
                    text={utils.getPatientURL(booking.code)}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<FileCopyIcon />}
                      style={{ textTransform: 'none' }}
                    >Copy Link</Button>
                  </CopyToClipboard>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    onClick={() => window.print()}
                  >Print</Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<LanguageIcon />}
                    onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'cht' : 'en')}
                  >{i18n.language === 'en' ? '中' : 'Eng'}</Button>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box p={2}>
              <Typography variant="h5" align="center">{ t('ntwcOnlineClinic') }</Typography>
              <Typography variant="h6" align="center">{booking.name} {isTestingClinic() ? '(TEST)' : ''}</Typography>
              <Typography variant="h6" align="center">{t('bookingDate')} {dayjs(booking.time).format('YYYY-MM-DD')} {t('bookingTime')} {dayjs(booking.time).format('HH:mm')}</Typography>
              <Typography variant="h6" align="center">{t('firstOnline')}</Typography>
            </Box>
            <Divider />
            <div className="non-print-area">
              <Table >
                <TableBody>
                  <TableRow>
                    <TableCell>SOPD</TableCell>
                    <TableCell>{booking.sopd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{ t('gender') }</TableCell>
                    <TableCell>{booking.gender.toUpperCase() ===  'M' ? t('male') : t('female')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{ t('dob') }</TableCell>
                    <TableCell>{booking.dob}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <Divider />
            <Grid container>
              <Grid item xs>
                <Typography align="center">{ t('linkScan') }</Typography>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item xs>
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                  <img src={patientQR} alt="Patient QR Code" />
                  <Typography variant="h4" style={{ fontWeight: 1000 }}>{booking.code}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                  <Typography variant="p" align="center">{ t('qrnotwork') }</Typography>
                  <Typography variant="p" align="center">{window.location.origin}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box p={1}>
              <Grid container>
                <Grid item xs={12}>
                  <PreparationList />
                </Grid>
                <Grid item xs={12}>
                  <CautionList />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box className="non-print-area">
              {children}
            </Box>
          </Card>
        }
      </DialogContent>
    </Dialog>
  )
}
