import React from 'react'
import {
  Typography,
} from '@material-ui/core'

import { bookingStatus } from '../../../libs/constants'

export default function BookingStatus(props) {
  const { booking } = props

  const seenEle = <Typography color="secondary" variant="body1">Seen</Typography>
  const canceledEle = <Typography color="secondary" variant="body1">Canceled</Typography>

  switch (booking.status) {
    case bookingStatus.seen:
      return seenEle
    case bookingStatus.canceled:
      return canceledEle
    default:
      return false
  }
}