import React from 'react'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'

export default function NumberPicker({from, to, label, value, native, onChange, minWidth}) {
  const numbers = []
  for (let i=from; i<=to; i++) {
    if (native) {
      numbers.push(<option key={label+i} value={i}>{i}</option>)
      continue
    }

    numbers.push(<MenuItem key={label+i} value={i}>{i}</MenuItem>)
  }

  return (
    <FormControl variant='outlined' style={{minWidth:minWidth ? minWidth : 100}} fullWidth>
      <InputLabel id={'select-' + label}>{label}</InputLabel>
      <Select labelId={'select-' + label} value={value} onChange={onChange} label={label} native={native} fullWidth>{numbers}</Select>
    </FormControl>
  )
}