import React, { useMemo, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import {
  Box,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Slide,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'
import DescriptionIcon from '@material-ui/icons/Description'
import TheatersIcon from '@material-ui/icons/Theaters'
import CloseIcon from '@material-ui/icons/Close'

import routes from '../../api/routes'
import location from '../../api/location'

import TextMaterial from './WaitingScreenComponents/TextMaterial'
import ImageMaterial from './WaitingScreenComponents/ImageMaterial'
import YoutubeMaterial from './WaitingScreenComponents/YoutubeMaterial'
import Messenger from '../Messenger/Messenger'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import LeafletDisplay from '../../components/LeafletDisplay'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function MaterialContent(props) {
  const { material } = props
  let element = null

  if (!material) {
    return false
  }

  switch (material.type) {
    case 'text':
      element = <TextMaterial material={material} />
      break
    case 'image':
      element = <ImageMaterial material={material} />
      break
    case 'youtube_link':
      element = <YoutubeMaterial material={material} />
      break
    default:
      return false
  }

  return (
    <>
      <DialogContent>
        {element}
      </DialogContent>
    </>
  )
}

function MaterialList(props) {
  const {
    materials,
    onItemClick,
  } = props

  if (!materials) {
    return false
  }

  const items = materials.map(m => {
    let icon = null

    switch (m.type) {
      case 'text':
        icon = <DescriptionIcon />
        break
      case 'image':
        icon = <ImageIcon />
        break
      case 'youtube_link':
        icon = <TheatersIcon />
        break
      default:
    }

    return (
      <ListItem button onClick={() => onItemClick && onItemClick(m)}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText >{m.title}</ListItemText>
      </ListItem>
    )
  })

  return <List>{items}</List>
}

function MaterialGroups(props) {
  const {
    patientCode,
    roomId,
    onItemClick,
  } = props
  const init = useRef(false)
  const [materialGroupList, setMaterialGroupList] = useState([])
  const [openIndex, setOpenIndex] = useState(null)

  const refresh = async () => {
    if (!roomId) {
      return
    }

    try {
      const { data } = await routes.patients.materialGroups(patientCode, roomId)
      setMaterialGroupList(data)
    } catch (err) {
      console.log(err)
      //alert('Error occurs when fetching material group list')
    }
  }

  const openClose = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
      refresh()
    }
  })

  return (
    <List>
      {materialGroupList.map((mg, index) => (
        <div key={mg.title}>
          <ListItem button onClick={() => openClose(index)}>
            <ListItemText primary={mg.title} />
            {index === openIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={index === openIndex} timeout="auto" unmountOnExit>
            <MaterialGroup
              patientCode={patientCode}
              materialGroup={mg}
              onItemClick={onItemClick}
            />
          </Collapse>
        </div>
      ))}
    </List>
  )
}

function MaterialGroup(props) {
  const {
    patientCode,
    materialGroup,
    onItemClick,
  } = props

  const init = useRef(false)
  const [materials, setMaterials] = useState([])

  const refresh = async () => {
    if (!materialGroup) {
      return
    }

    try {
      const { data } = await routes.patients.materials(patientCode, materialGroup.id)
      setMaterials(data)
    } catch (err) {
      console.log(err)
      //alert('Error occurs when fetching materials')
    }
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
      refresh()
    }
  })

  return <MaterialList materials={materials} onItemClick={onItemClick} />
}

export default function WaitingScreen(props) {
  const {
    room,
    patient,
    receiver,
    messages,
    setMessages,
    onSendMessage,
  } = props

  const { t } = useTranslation()

  const time = useMemo(() => {
    return dayjs(patient.time).format('YYYY-MM-DD HH:mm:ss')
  }, [patient])

  const [selectedMaterial, setSelectedMaterial] = useState()

  const [isLeafletOpen, setLeafletOpen] = useState(false)
  const [leafletList, setLeafletList] = useState([])
  const [selectedLeaflet, setSelectedLeaflet] = useState(-1)
  const [leafletLink, setLeafletLink] = useState(null)

  function addWelcomeMessage() {
    if (messages.length) {
      return
    }

    const newMessages = [...messages]
    const now = dayjs()
    const bookingTime = dayjs(patient.time)

    newMessages.push({
      code: null,
      msg: `預約時間: ${time}\nAppointment Date/Time: ${time}`,
    })

    if (now.isBefore(bookingTime)) {
      const waitingTime = bookingTime.diff(now, 'minute')

      newMessages.push({
        code: null,
        msg: `您好，${patient.name}！ 仍未到預約時間，請停在這畫面留耐心等候。\nHi, ${patient.name}! The appointment time has not yet arrived, please wait on this screen.
        `,
      })

      newMessages.push({
        code: null,
        msg: `距離預約時間: ${waitingTime} 分鍾\nTime from appointment: ${waitingTime} minute${waitingTime > 1 ? 's' : ''}`,
      })
    } else {
      newMessages.push({
        code: null,
        msg: `您好，${patient.name}！ 請耐心等候並留在此畫面等候，我們會盡快連接。\nHi, ${patient.name}! Please be patience and also stay on this screen, we will get to you soon. `,
      })

      newMessages.push({
        code: null,
        msg: `預計輪候時間: ${room.interval} 分鐘\nEstimated Waiting Time: ${room.interval} minutes `,
      })
    }

    setMessages(newMessages)
  }

  function onSelectedLeafletChange(event) {
    const id = event.target.value
    setSelectedLeaflet(id)
    const selected = leafletList[id]
    setLeafletLink(`${location.backendURL()}/viewleaflets/${selected.id}`)
  }

  function fetchLeaflet() {
    routes.leaflets.fetch().then(res => {
      setLeafletList(res.data)
      if (res.data.length !== 0) {
        setTimeout(() => {
          openLeaflet()
        }, 3000)
        setSelectedLeaflet(0)
        const selected = res.data[0]
        setLeafletLink(`${location.backendURL()}/viewleaflets/${selected.id}`)
      }
    })
  }

  function openLeaflet() {
      setLeafletOpen(true)
  }

  const onItemClick = (material) => {
    setSelectedMaterial(material)
  }

  useEffect(() => {
    fetchLeaflet()
  }, [])

  useEffect(() => {
    addWelcomeMessage()
  })

  return (
    <Container>
      <Paper variant="outlined">
        <Box p={3}>
          <Box pb={3}>
            <Typography variant="h3" style={{ color: room.color }}>{room.name}</Typography>
            <LinearProgress />
          </Box>

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Messenger
                color={room.color}
                sender={patient.code}
                receiver={receiver}
                receiverName=""
                messages={messages}
                height={250}
                onSendMessage={onSendMessage}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={1} mt={1}>
          <MaterialGroups
            patientCode={patient.code}
            roomId={room.id}
            onItemClick={onItemClick}
          ></MaterialGroups>
        </Box>
      </Paper>

      <Dialog
        fullWidth
        maxWidth="xl"
        scroll="paper"
        open={!!selectedMaterial}
        onClose={() => setSelectedMaterial(null)}
      >
        <DialogTitle>
          <Grid container alignItems="center" justify="flex-end">
            <Grid item>
              <IconButton
                onClick={() => setSelectedMaterial(null)}
                color="primary"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <MaterialContent material={selectedMaterial} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="lg"
        scroll="paper"
        TransitionComponent={Transition}
        open={isLeafletOpen}
        onClose={() => setLeafletOpen(false)}
      >
        {isLeafletOpen && leafletLink !== '' && 
          <>
            <Box p={3}>
              <Box display="flex" justifyContent="center" p={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="leaflet-label">Current leaflet</InputLabel>
                  <Select
                    labelId="leaflet-label"
                    value={selectedLeaflet}
                    fullWidth
                    onChange={onSelectedLeafletChange}
                  >
                    { leafletList.map((leaf, index) => (
                      <MenuItem value={index} key={index}>{leaf.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              { selectedLeaflet !== -1 && 
                <LeafletDisplay
                  path={leafletLink}
                />
              }
              <Box display="flex" justifyContent="center" p={3}>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => { setLeafletOpen(false) }}
                  >{t('leaflet.dont_want_see')}</Button>
              </Box>
            </Box>
          </>
        }
      </Dialog>
    </Container>
  )
}