import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Card,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Snackbar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Divider,
  Dialog,
  Slide,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import RefreshIcon from '@material-ui/icons/Refresh'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import DescriptionIcon from '@material-ui/icons/Description'
import LinkIcon from '@material-ui/icons/Link'

import api from '../../api'
import utils from '../../utils'

import MaterialGroupList from '../../components/ClinicManagement/MaterialGroupList'
import SurveyLinkEditor from '../../components/ClinicManagement/SurveyLinkEditor'

function ListedTableBody(props) {
  const {
    list,
    onMaterialClick,
    onSurveyLinkEdit,
  } = props
  let rows = []

  if (list && Array.isArray(list)) {
    rows = list.map(item => {
      const activatedColor = item.activated ? item.color : '#BDBDBD'

      return (
        <TableRow key={item.key}>
          <TableCell>{item.name}</TableCell>
          <TableCell align="center">
            <EmojiObjectsIcon style={{ color: activatedColor }} />
          </TableCell>
          <TableCell align="center">
            <IconButton color="primary" onClick={() => onMaterialClick(item)}>
              <DescriptionIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => onSurveyLinkEdit(item)}>
              <LinkIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }

  return <TableBody>{rows}</TableBody>
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref}
    mountOnEnter
    unmountOnExit
    {...props}
  />
})

function AdminClinicManagement(props) {
  const history = useHistory()
  const {
    roles,
  } = props
  const [loading, setLoading] = useState(false)
  const [clinicList, setClinicList] = useState([])
  const [clinicListErr, setClinicListErr] = useState(false)

  const init = useRef(false)
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [showMaterials, setShowMaterials] = useState(false)
  const [showSurveyLink, setShowSurveyLink] = useState(false)
  const editing = useRef(false)

  async function refresh() {
    if (editing.current) {
      setTimeout(refresh, 1000)
      return
    }

    try {
      setLoading(true)
      const { data } = await api.routes.rooms.list()
      setClinicList(data)
      setClinicListErr(false)
    } catch (err) {
      setClinicListErr(err)
    } finally {
      setLoading(false)
    }
  }

  function onMaterialClick(room) {
    editing.current = true
    setSelectedRoom(room)
    setShowMaterials(true)
  }

  function onSurveyLinkEdit(room) {
    editing.current = true
    setShowSurveyLink(true)
    setSelectedRoom(room)
  }

  function closeAllDialog(shouldRefresh) {
    editing.current = false
    setShowMaterials(false)
    setShowSurveyLink(false)
    setSelectedRoom(null)

    if (shouldRefresh) {
      refresh()
    }
  }

  useEffect(() => {
    if (!init.current) {
      init.current = true
      refresh()
    }

    return
  })

  if (!utils.isSuper(roles) && !utils.isAdmin(roles)) {
    history.replace('/admin')
    return false
  }

  return (
    <>
      <Card variant="outlined">
        {clinicListErr &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={true}
          >
            <MuiAlert
              variant="filled"
              severity="error"
            >Error occurs when loading clinic list</MuiAlert>
          </Snackbar>
        }
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6">Clinic Management</Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        {loading && <LinearProgress />}
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Activated</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <ListedTableBody
              list={clinicList}
              onMaterialClick={onMaterialClick}
              onSurveyLinkEdit={onSurveyLinkEdit}
            />
          </Table>
        </TableContainer>
      </Card>

      <Dialog
        fullScreen
        open={showMaterials}
        onClose={closeAllDialog}
        TransitionComponent={Transition}
      >
        {selectedRoom && <MaterialGroupList
          room={selectedRoom}
          onCloseClick={closeAllDialog}
        />}
      </Dialog>

      {selectedRoom &&
        <SurveyLinkEditor
          room={selectedRoom}
          open={showSurveyLink}
          onClose={closeAllDialog}
          TransitionComponent={Transition}
        />
      }
    </>
  )
}

export default connect(state => ({
  roles: state.user.roles,
}))(AdminClinicManagement)