export default function patientReducer(state = {
  id: '',
  sopd: '',
  name: '',
  gender: '',
  dob: '',
  time: '',
  code: '',
  status: '',
}, action) {
  switch (action.type) {
    case 'SET_PATIENT_STATE':
      return {
        ...state,
        id: action.id,
        sopd: action.sopd,
        name: action.name,
        gender: action.gender,
        dob: action.dob,
        time: action.time,
        code: action.code,
        status: action.status,
      }
    case 'LOGOUT':
      return {
        id: '',
        sopd: '',
        name: '',
        gender: '',
        dob: '',
        time: '',
        code: '',
        status: '',
      }
    default:
      return state
  }
}