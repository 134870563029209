import React from 'react'
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core'

function HKIDVerify({
    HKID,
    setHKID,
}) {
    return (
        <Grid item xs={12} md={12} lg={12}>
            <Box display="flex" alignItems="center" fullWidth>
                <FormControl fullWidth>
                    <TextField
                        variant="outlined"
                        label='香港身份證頭四位數字'
                        value={HKID}
                        onChange={(e) => setHKID(e.target.value)}
                        fullWidth
                    ></TextField>
                </FormControl>
            </Box>
        </Grid>
        
    )
}

export default HKIDVerify