import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core'

import ListAltIcon from '@material-ui/icons/ListAlt'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ApartmentIcon from '@material-ui/icons/Apartment'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import SettingsIcon from '@material-ui/icons/Settings'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'

import api from '../api'
import utils from '../utils'
import actions from '../store/actions'

function SideMenu(props) {
  const {
    open,
    onClose,
    onOpen,
    dispatch,
    username,
    roles,
    room,
  } = props
  const history = useHistory()

  function goToRoomListHandler() {
    onClose()
    dispatch(actions.clearRoomState())
    history.push('/admin/')
  }

  function goToUserManagementPage() {
    onClose()
    history.push('/admin/user_management')
  }

  function goToClinicManagementPage() {
    onClose()
    history.push('/admin/clinic_management')
  }

  function goToSummaryPage() {
    onClose()
    history.push('/admin/summary_report')
  }

  function goToSettingManagementPage() {
    onClose()
    history.push('/admin/setting_management')
  }

  function goToGroupEducationPage() {
    onClose()
    history.push('/admin/group_education')
  }

  async function logoutHandler() {
    try {
      onClose()
      await api.routes.auth.logout()
      dispatch(actions.logout())
      history.push('/admin/login')
    } catch (err) {
      // TODO error handling
    }
  }

  const shouldShowGroupEducation = useMemo(() => {
    if (!room) return false
    if (room.key === utils.constants.room_identifier.surgery_school) return true
    if (room.key === utils.constants.room_identifier.npac) return true
    return false
  }, [room])

  const groupEducationLabel = useMemo(() => {
    if (!room) return ''
    if (room.key === utils.constants.room_identifier.surgery_school) return 'Group Education'
    if (room.key === utils.constants.room_identifier.npac) return 'Number of Patient Attendance'
  }, [room])

  if (!username) {
    return false
  }

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <List>
        <ListItem button onClick={goToRoomListHandler}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText>Room List</ListItemText>
        </ListItem>
        {shouldShowGroupEducation && (
          <>
            <ListItem button onClick={goToGroupEducationPage}>
              <ListItemIcon>
                <EventAvailableIcon />
              </ListItemIcon>
              <ListItemText>{groupEducationLabel}</ListItemText>
          </ListItem>
          </>
        )}
        {(utils.isSuper(roles) || utils.isAdmin(roles)) &&
          <>
            <ListItem button onClick={goToUserManagementPage}>
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText>User Management</ListItemText>
            </ListItem>
            <ListItem button onClick={goToClinicManagementPage}>
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText>Clinic Management</ListItemText>
            </ListItem>
            <ListItem button onClick={goToSummaryPage}>
            <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText>Summary Report</ListItemText>
            </ListItem>
          </>
        }
        {(utils.isSuper(roles)) && 
          <>
            <ListItem button onClick={goToSettingManagementPage}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Setting Management</ListItemText>
            </ListItem>
          </>
        }
        <ListItem button onClick={logoutHandler}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItem>
      </List>
    </SwipeableDrawer>
  )
}

export default connect(state => ({
  username: state.user.username,
  roles: state.user.roles,
  room: state.room,
}))(SideMenu)