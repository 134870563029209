import user from './user'
import room from './room'
import patient from './patient'

const reducers = {
  user,
  room,
  patient,
}

export default reducers