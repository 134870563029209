import React, { useRef, useEffect } from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Dialog,
  DialogContent,
  Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStream from '../../hooks/useStream'

import Messenger from '../Messenger/Messenger'
import Survey from '../Survey/Survey'

const useStyles = makeStyles({
  video: {
    display: 'block',
    width: '100%',
    maxWidth: 1280,
    backgroundColor: '#000',
  },
  littleVideo: {
    position:'absolute',
    width:'20%',
    height:'20%',
    top: 0,
    right: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
})

function VideoBox(props) {
  const { stream, muted } = props

  const classes = useStyles()
  const videoRef = useRef(null)

  useEffect(() => {
    init()
  })

  function init() {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream
    }
  }

  return <video
    ref={videoRef}
    className={muted ? classes.littleVideo : classes.video }
    autoPlay
    playsInline
    muted={muted}
  ></video>
}

const MemoVideoBox = React.memo(VideoBox)

export default function ChatScreen(props) {
  const {
    room,
    remoteStream,
    patient,
    messages,
    showSurvey, 
    setShowSurvey,
    onSendMessage,
  } = props
  const videoRef = useRef(null)
  const remoteVideoRef = useRef(null)
  const {
    getStream,
  } = useStream()

  const { t } = useTranslation()

  useEffect(() => {

    if (getStream() && typeof getStream() === 'object' && videoRef.current) {
      videoRef.current.srcObject = getStream()
    }

    if (remoteStream && remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream
    }
  })

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Box display="flex" justifyContent="center">
            <Box position="relative" width="100%" maxWidth={1280}>
              <MemoVideoBox
                stream={remoteStream}
              />
              <MemoVideoBox
                stream={getStream()}
                muted
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Messenger
            color={room.color}
            sender={patient.code}
            receiver=''
            messages={messages}
            onSendMessage={onSendMessage}
            height={300}
          />
        </Grid>
        <Box p={3}></Box>
        {/* <Grid item xs={12} sm={12} md={12}>
          <Button
            color="primary"
            margin="normal"
            fullWidth
            variant="contained"
            onClick={() => setShowSurvey(true)}
          >{t('surveytitle')}</Button>
        </Grid> */}
      </Grid>
      {showSurvey &&
        <Dialog
          fullWidth
          maxWidth="lg"
          scroll="paper"
          open={showSurvey}
          onClose={() => setShowSurvey(false) }
        >
          <DialogContent
            title={t('surveytitle')}
            onClose={() => setShowSurvey(false)}
          >
            <Survey
              code={patient.code}
              setShowSurvey={setShowSurvey}
            />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}