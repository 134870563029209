import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import {
  makeStyles,
  Box,
  Card,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  Typography,
  TextField,
} from '@material-ui/core'
import DateDayjsUtils from '@date-io/dayjs'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

import BookingTable from '../Schedules/BookingTable'
import PatientQRCodeDialog from '../../PatientQRCodeDialog'
import BookingForm from '../BookingForm'
import VitalRecords from '../VitalRecords'
import DialogContent from '../../DialogContent'

import routes from '../../../api/routes'

import StatusSelect from './StatusSelect'
import Paging from '../../Paging'

const useStyles = makeStyles((theme) => ({
  textField: ({ color }) => ({
    '& label.Mui-focused': {
      color: color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: color,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: color,
      },
      '&:hover fieldset': {
        borderColor: color,
      },
      '&.Mui-focused fieldset': {
        borderColor: color,
      },
    },
  }),
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ScheduleList({ room }) {
  const classes = useStyles({ color: room.color })
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(null)
  const [bookings, setBookings] = useState([])
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [editingBooking, setEditingBooking] = useState(null)
  const [selectedVitalPatient, setSelectedVitalPatient] = useState(null)
  const shouldRefresh = useRef(true)

  const [sopd, setSopd] = useState('')
  const [phone, setPhone] = useState('')
  const [status, setStatus] = useState('upcoming')
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const onStartDateChange = (date) => {
    shouldRefresh.current = true
    setStartDate(date)
  }

  const onEndDateChange = (date) => {
    shouldRefresh.current = true
    setEndDate(date)
  }

  const refresh = async () => {
    const dateFormat = 'YYYY-MM-DD'
    let endDateStr = ''
    if (endDate) {
      endDateStr= endDate.format(dateFormat)
    }
    const { data } = await routes.bookings.list(
      room.id,
      '',
      startDate.format(dateFormat),
      endDateStr,
      status,
      page - 1,
      sopd,
      phone,
    )

    setBookings(data.bookings)
    setTotalCount(data.count)
  }

  const deleteBookingHandler = async (booking) => {
    if (window.confirm('Confirm to remove record?')) {
      await routes.bookings.destroy(booking.id)
      refresh()
    }
  }

  const onBookingLinkClickHandler = (booking) => {
    setSelectedBooking(booking)
  }

  const onBookingEditClickHandler = (booking) => {
    setEditingBooking(booking)
  }

  const onVitalRecordsClickHandler = (booking) => {
    setSelectedVitalPatient(booking)
  }

  const onBookingUpdatedHandler = () => {
    setEditingBooking(null)
    refresh()
  }

  const onStatusChangeHandler = (e) => {
    const value = e.target.value
    setStatus(value)
  }

  const onSOPDChangeHandler = (e) => {
    setSopd(e.target.value.toUpperCase())
  }

  const onPhoneChangeHandler = (e) => {
    setPhone(e.target.value.trim())
  }


  const onPageChangeHandler = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (shouldRefresh.current) {
      shouldRefresh.current = false
      refresh()
    }
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1)
      refresh()
    }, 500)

    return () => clearTimeout(timer)
  }, [sopd, phone])


  useEffect(() => {
    setPage(1)
    refresh()
  }, [status])

  useEffect(() => {
    refresh()
  }, [page])

  return (
    <Box mt={3}>
      <MuiPickersUtilsProvider utils={DateDayjsUtils}>
        <Paper variant="outlined">
          <Box p={3}>
            <Grid container spacing={3} alignContent="center">
              <Grid item xs={3}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  label="Start Date"
                  format="YYYY-MM-DD"
                  value={startDate}
                  onChange={onStartDateChange}
                />
              </Grid>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  label="End Date"
                  format="YYYY-MM-DD"
                  value={endDate}
                  onChange={onEndDateChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" alignContent="flex-end" height="100%">
                  <StatusSelect
                    currentStatus={status}
                    setStatus={onStatusChangeHandler}
                    color={room.color}
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="SOPD"
                  className={classes.textField}
                  onChange={onSOPDChangeHandler}
                  value={sopd}
                />
              </Grid>
              <Grid item xs={2} fullWidth>
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Phone Number"
            className={classes.textField}
            onChange={onPhoneChangeHandler}
            value={phone}
          />
        </Grid>
            </Grid>
          </Box>
        </Paper>
      </MuiPickersUtilsProvider>

      <Box mt={3}></Box>

      <Grid container spacing={3}>
        <Grid item xs>
          <Card variant="outlined">
            <BookingTable
              room={room}
              bookings={bookings}
              onDeleteClick={deleteBookingHandler}
              onBookingLinkClick={onBookingLinkClickHandler}
              onBookingEditClick={onBookingEditClickHandler}
              onVitalRecordsClick={onVitalRecordsClickHandler}
              showDate={true}
            />
            <Divider />
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <Typography color="secondary" variant="body1" display="inline">Seen</Typography>
                <Typography display="inline"> = finished consultation</Typography>
              </Box>
            </Box>
            <Divider />
            <Paging
              totalCount={totalCount}
              page={page}
              onPageChange={onPageChangeHandler}
            />
          </Card>
        </Grid>
      </Grid>

      <PatientQRCodeDialog
        booking={selectedBooking}
        onClose={() => setSelectedBooking(null)}
      ></PatientQRCodeDialog>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={!!editingBooking}
        onClose={() => setEditingBooking(null)}
      >
        {editingBooking &&
          <>
            <Box p={3}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  size="small"
                  onClick={() => setEditingBooking(null)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <BookingForm
                room={room}
                booking={editingBooking}
                onBookingUpdated={onBookingUpdatedHandler}
              />
            </Box>
          </>
        }
      </Dialog>

      <Dialog
        open={!!selectedVitalPatient}
        onClose={() => setSelectedVitalPatient(null)}
        keepMounted
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogContent
          title={`Vital Records (${selectedVitalPatient?.name})`}
          onClose={() => setSelectedVitalPatient(null)}
        >
          {selectedVitalPatient && <VitalRecords hn={selectedVitalPatient.sopd} />}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ScheduleList