const cht = {
  translation: {
    lang: 'cht',
    title: '線上護士診所',

    submit: '提交',
    gender: '性別',
    dob: '出生日期',
    male: '男',
    female: '女',
    date: '日期',
    time: '時間',
    remark: '備註',
    success: '成功',
    back: '返回',
    cancel: '取消',

    surveytitle: '問卷調查',

    // Home
    startConversation: '開始對話',
    submitData: '提交數據',
    checkBooking: '查看預約',
    saveCode: '儲存代碼',

    // PatientQRCodeDialog
    ntwcOnlineClinic: '新界西麻醉科線上護士診所',
    futureUse: '日後覆診使用，需輸入五位編碼。',
    linkScan: '請掃描二維碼應診',
    bookingDate: '覆診日期',
    bookingTime: '時間',
    qrnotwork: '若無法使用二維碼，請使用下方連結',
    firstOnline: '第一次線上覆診',

    // PatientLogin
    noAppointment: '所輸入的資料有錯，或找不到預約。請檢查所輸入的資料和預約時間。謝謝！',

    // CodeEnter
    enterCode: '請輸入代碼以開始程序。',
    loginFailed: '找不到預約，預約可能已過期或今天不是預約的日子。請檢查後再在預約當日回來嘗試。謝謝！',
    invalidCode: '代碼錯誤',

    // Info Enter
    enterInfo: '請輸入身份證頭四個數字和出生年份以尋找你的預約',
    infoFailed: '找不到預約',
    phone: '電話號碼',
    hn: 'HN號碼',
    confirm: '確定',

    // Patient Booking
    patientBooking: {
      title: '這是你的預約',
      lastname: '姓氏',
      bookingDate: '預約日期',
      bookingTime: '預約時間',
      conversationType: '方式',
      status: '狀態',
    },

    // Preparation List
    preparationList: {
      title: '準備事項',
      first: '確保有穩定的網絡，若果遇上視像中斷，護士會再次聯絡病人，若然未能成功聯絡，護士將會打電話回覆病人。',
      second: '請在安靜及保密的環境下進行視像對話，以免病人資料外洩。',
      third: '請準備病人覆診日期及病人服用的藥物名稱。',
      fourth: '請準備病人身份證正本/副本，以便護士核對身份。',
    },

    // Precautions
    precautions: {
      title: '注意事項',
      1: '視像診所費用全免。',
      2: '視像對話途中不可錄音/錄影。',
      3: '視像診所所收集的資料，只會作醫療用途。',
      4: '請於預約時間前最少5分鐘登入系統，等候護士接見。',
      5: '逾時15分鐘，是次預約將會自動取消。',
      6: '每次視像會見只限30分鍾（包括遲到時間）。',
      7: '視像診所服務並不提供體檢、處方藥物、病假/到診證明。',
    },

    vitalSign: {
      sysdia: '血壓(mmHg)(格式: XX/XX)',
      map: '平均動脈壓(mmHg)',
      temperature: '體溫(°C)',
      hstix: '血糖(mmol/L)',
      spo2: '血氧飽和度(%)',
      weight: '體重(kg)',
      imt: '呼吸肌訓練(cmH2O)',
      cigarette: '每天吸煙數量',
      aerobic: '帶氧運動',
    },

    patientLogin: {
      hello: '你好',
      verify_identity: '請輸入以下資料以核實身份',
      first_four_digit: '香港身份證頭四位數字',
      gender: '性別',
      year: '出生年份',
      male: '男',
      female: '女',
      enter: '進入',
    },

    survey: {
      score: {
        highlyagree: '非常同意',
        agree: '同意',
        normal: '一般',
        disagree: '不同意',
        highlydisagree: '非常不同意',
      },
      question: {
        topic: '為提升我們的服務質素，請您給予寶貴意見',
        1: '我對線上服務感到滿意',
        2: '我對護士服務感到滿意',
        3: '線上護士診所簡單易用',
      },
      finished: '感謝你的寶貴意見',
      missinganswer: '請填下所有答案',
    },

    leaflet: {
      next: '另一張',
      dont_want_see: '關閉',
    },
  },
}

export default cht