import { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { routes } from '../../api'

const useStyle = makeStyles(theme => ({
  paper: {
    minWidth: 400,
  },
}))

export default function SurveyLinkEditor(props) {
  const {
    room,
    open,
    onClose,
    TransitionComponent,
  } = props

  const classes = useStyle()
  const [surveyLink, setSurveyLink] = useState(room.surveyLink)

  async function updateHandler() {
    if (surveyLink && !/(http(s?):\/\/(.+))/si.test(surveyLink)) {
      alert('The link form is not valid')
      return
    }

    try {
      await routes.rooms.update(room.id, {
        surveyLink,
      })

      onClose(true)
    } catch (err) {
      // TODO error Handling
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      TransitionComponent={TransitionComponent}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">Survey Link</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Survey Link"
          fullWidth
          value={surveyLink}
          onChange={e => setSurveyLink(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={updateHandler} color="primary" variant="outlined">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}