import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Card,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'

import ReasonTable from '../CancelReasons/ReasonTable'
import ReasonForm from '../CancelReasons/ReasonForm'

import routes from '../../../api/routes'

function CancelReasonList() {
    const [reasons, setReasons] = useState([])
    const [creatingReason, setCreatingReason] = useState(false)
    const [editingReason, setEditingReason] = useState(null)
    const shouldRefresh = useRef(true)

    const refresh = async () => {
        const { data } = await routes.cancelReasons.list()
        setReasons(data)
    }

    const deleteReasonHandler = async (reason) => {
        if (window.confirm('Confirm to remove cancel reason?')) {
            await routes.cancelReasons.delete(reason.id)
            refresh()
        }
    }

    const onReasonEditClickHandler = (reason) => {
        setEditingReason(reason)
    }

    const onReasonUpdateHandler = () => {
        closeReasonDialog()
        refresh()
    }

    const onReasonCreateHandler = () => {
        closeReasonDialog()
        refresh()
    }

    const closeReasonDialog = () => {
        setEditingReason(null)
        setCreatingReason(false)
    }

    useEffect(() => {
        if (shouldRefresh.current) {
            shouldRefresh.current = false
            refresh()
        }
    })

    return (
        <Box mt={3}>
            <Paper variant="outlined">
                <Box p={3}>
                    <Grid container spacing={3} alignContent="center">
                        <Grid item xs>
                            <Button 
                                color="inherit"
                                startIcon={<AddIcon />}
                                onClick={() => setCreatingReason(true)}
                            >Add Reason</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs>
                    <Card variant="outlined">
                        <ReasonTable
                            reasons={reasons}
                            onDeleteClick={deleteReasonHandler}
                            onReasonEditClick={onReasonEditClickHandler}
                        />
                    </Card>
                </Grid>
            </Grid>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={!!editingReason}
                onClose={() => setEditingReason(null)}
            >
                {editingReason &&
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setEditingReason(null)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <ReasonForm
                                onCancelClick={() => setEditingReason(null)}
                                cancelReason={editingReason}
                                onReasonUpdated={onReasonUpdateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>

            <Dialog
                fullWidth
                maxWidth="lg"
                open={creatingReason}
                onClose={() => setCreatingReason(false)}
            >
                {creatingReason && 
                    <>
                        <Box p={3}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => setCreatingReason(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <ReasonForm
                                onCancelClick={() => setCreatingReason(false)}
                                cancelReason={null}
                                onReasonCreated={onReasonCreateHandler}
                            />
                        </Box>
                    </>
                }
            </Dialog>
        </Box>
    )
}

export default CancelReasonList