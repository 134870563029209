import { useTranslation } from 'react-i18next'
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core'

export default function PreparationList() {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h5" align="center">
        <Divider />
        <Box mb={1} mt={3}>
          <b>{t('preparationList.title')}</b>
        </Box>
      </Typography>
      <ol style={{padding: '0 16px'}}>
        <li><Typography variant="body1">{t('preparationList.first')}</Typography></li>
        <li><Typography variant="body1">{t('preparationList.second')}</Typography></li>
        <li><Typography variant="body1">{t('preparationList.third')}</Typography></li>
        <li><Typography variant="body1">{t('preparationList.fourth')}</Typography></li>
      </ol>
    </>
  )
}