import React, { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import {
  Grid,
  makeStyles,
  Paper,
  Box,
  Typography,
  Divider,
  useTheme,
  Dialog,
  IconButton,
  Slide,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import './Schedules.css'
import api from '../../../api'

import BookingTable from './BookingTable'
import TableHeader from './TableHeader'
import PatientQRCodeDialog from '../../PatientQRCodeDialog'
import BookingForm from '../BookingForm'
import DialogContent from '../../DialogContent'
import VitalRecords from '../VitalRecords'

import StatusSelect from '../ScheduleList/StatusSelect'
import Paging from '../../Paging'

const useStyles = makeStyles((theme) => ({
  textField: ({ color }) => ({
    '& label.Mui-focused': {
      color: color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: color,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: color,
      },
      '&:hover fieldset': {
        borderColor: color,
      },
      '&.Mui-focused fieldset': {
        borderColor: color,
      },
    },
  }),
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Schedules(props) {
  const { room } = props
  const classes = useStyles({ color: room.color })
  const theme = useTheme()
  const [pickedDate, setPickedDate] = useState(new Date())
  const [bookings, setBookings] = useState([])
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [editingBooking, setEditingBooking] = useState(null)
  const [vitalPatient, setVitalPatient] = useState(null)
  const shouldRefresh = useRef(true)

  const [sopd, setSopd] = useState('')
  const [phone, setPhone] = useState('')
  
  const [status, setStatus] = useState('upcoming')
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)

  const refresh = async () => {
    const { data } = await api.routes.bookings.list(
      room.id,
      '',
      dayjs(pickedDate).format('YYYY-MM-DD'),
      dayjs(pickedDate).format('YYYY-MM-DD'),
      status,
      page - 1,
      sopd,
      phone,
    )
      
    setBookings(data.bookings)
    setTotalCount(data.count)
  }

  const pickedDateChangeHandler = (date) => {
    try {
      shouldRefresh.current = true
      setPickedDate(date)
    } catch (err) {
      alert('Error occurs when loading appointments!')
    }
  }

  const deleteBookingHandler = async (booking) => {
    if (window.confirm('Confirm to remove record?')) {
      await api.routes.bookings.destroy(booking.id)
      refresh()
    }
  }

  const onBookingLinkClickHandler = (booking) => {
    setSelectedBooking(booking)
  }

  const onBookingEditClickHandler = (booking) => {
    setEditingBooking(booking)
  }

  const onVitalRecordsClickHandler = (booking) => {
    setVitalPatient(booking)
  }

  const onBookingUpdatedHandler = (booking) => {
    pickedDateChangeHandler(pickedDate)
    setEditingBooking(null)
  }

  const onStatusChangeHandler = (e) => {
    setStatus(e.target.value)
  }

  const onSOPDChangeHandler = (e) => {
    setSopd(e.target.value.toUpperCase())
  }

  const onPhoneChangeHandler = (e) => {
    setPhone(e.target.value.trim())
  }

  const onPageChangeHandler = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    if (shouldRefresh.current) {
      shouldRefresh.current = false
      refresh()
    }
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1)
      refresh()
    }, 500)

    return () => clearTimeout(timer)
  }, [sopd, phone])

  useEffect(() => {
    setPage(1)
    refresh()
  }, [status])

  useEffect(() => {
    refresh()
  }, [page])

  return (
    <Box mt={3}>
      <Grid container spacing={3} alignContent="center">
        <Grid item xs={3} fullWidth>
          <Box display="flex" alignContent="flex-end" height="100%">
             <StatusSelect
                currentStatus={status}
                setStatus={onStatusChangeHandler}
                color={room.color}
              />
          </Box>
        </Grid>
        <Grid item xs={3} fullWidth>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="SOPD"
            className={classes.textField}
            onChange={onSOPDChangeHandler}
            value={sopd}
          />
        </Grid>
        <Grid item xs={3} fullWidth>
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Phone Number"
            className={classes.textField}
            onChange={onPhoneChangeHandler}
            value={phone}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Paper variant="outlined">
            <TableHeader date={pickedDate} bookings={bookings} />
            <Divider />
            <BookingTable
              room={room}
              bookings={bookings}
              onDeleteClick={deleteBookingHandler}
              onBookingLinkClick={onBookingLinkClickHandler}
              onBookingEditClick={onBookingEditClickHandler}
              onVitalRecordsClick={onVitalRecordsClickHandler}
              showDate={true}
              highlightDate={pickedDate}
            />
            <Divider />
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <Typography color="secondary" variant="body1" display="inline">Seen</Typography>
                <Typography display="inline"> = finished consultation</Typography>
              </Box>
            </Box>
            <Divider />
            <Paging
              totalCount={totalCount}
              page={page}
              onPageChange={onPageChangeHandler}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box display="flex" justifyContent={theme.breakpoints.up('sm') ? 'center' : 'start'}>
            <Calendar
              value={pickedDate}
              onChange={pickedDateChangeHandler}
            ></Calendar>
          </Box>
        </Grid>
      </Grid>

      <PatientQRCodeDialog
        booking={selectedBooking}
        onClose={() => setSelectedBooking(null)}
      ></PatientQRCodeDialog>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={!!editingBooking}
        onClose={() => setEditingBooking(null)}
      >
        {editingBooking &&
          <>
            <Box p={3}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  size="small"
                  onClick={() => setEditingBooking(null)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <BookingForm
                room={room}
                booking={editingBooking}
                onBookingUpdated={onBookingUpdatedHandler}
              />
            </Box>
          </>
        }
      </Dialog>

      <Dialog
        open={!!vitalPatient}
        onClose={() => setVitalPatient(null)}
        fullScreen
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogContent
          title={`Vital Records (${vitalPatient?.name})`}
          onClose={() => setVitalPatient(null)}
        >
          {vitalPatient && <VitalRecords hn={vitalPatient.sopd} />}
        </DialogContent>
      </Dialog>
    </Box>
  )
}