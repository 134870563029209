import React from 'react'

export default function YoutubeElement({ content }) {
  const url = new URL(content)
  const code = url.searchParams.get('v')

  return <iframe
    title={content}
    style={{
      maxWidth: 320,
      maxHeight: 180,
    }}
    src={'https://www.youtube.com/embed/' + code}
    frameBorder="0"
    allowFullScreen
  ></iframe>
}