import { useState } from 'react'

import InfoEnter from '../components/InfoEnter'
import PatientBookingList from '../components/PatientBookingList'

function Booking() {
    const [bookList, setList] = useState([])

    if (bookList.length === 0) {
        return <InfoEnter
            onBookingList={list => setList(list)}
        />
    }

    return <PatientBookingList
        bookingList={bookList}
    />
}

export default Booking