import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Grid,
  FormControl,
  Typography,
  Button,
  Card,
  Box,
  TextField,
} from '@material-ui/core'
import { useHistory } from 'react-router'

import NumberPicker from './NumberPicker'
import HKIDVerify from './PatientVerify/HKIDVerify'

import { routes } from '../api'

export default function InfoEnter(props) {
    const {
		onBookingList,
    } = props

	const history = useHistory()
    const { t } = useTranslation()

	const [year, setYear] = useState(2000)
	const [hkid, setHKID] = useState('')

	function submitHandler(e) {
		e.preventDefault()

		if (hkid && year) {
			routes.patients.checkBooking(hkid, year)
				.then(res => {
					if (res.data.length === 0) {
						alert(t('infoFailed'))
					} else {
						onBookingList(res.data)
					}
				})
		}
	}

    return (
		<Container>
			<Card variant="outlined">
				<Box p={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={12} md={12}>
						<Typography variant="h4" align="center">{ t('enterInfo') }</Typography>
						<form noValidate onSubmit={submitHandler}>
							<Grid container>
								<Grid item xs={12} lg={6} md={6}>
									<Box display="flex" justifyContent="center" p={3}>
										<FormControl style={{minWidth:100}} fullWidth>
										<TextField
											fullWidth
											variant="outlined"
											label={t('patientLogin.first_four_digit')}
											value={hkid}
											onChange={e => setHKID(e.target.value)}
										></TextField>
										</FormControl>
									</Box>
								</Grid>
								<Grid item xs={12} lg={6} md={6}>
									<Box display="flex" justifyContent="center" p={3}>
										<NumberPicker
										from={1600}
										to={2020}
										label={t('patientLogin.year')}
										value={year}
										native
										onChange={e => setYear(e.target.value)}
										/>
									</Box>
								</Grid>
							</Grid>
							<Box display="flex" justifyContent="center" p={3}>
								<Button
									color="primary"
									variant="contained"
									disabled={!hkid.length}
									type="submit"
								>{t('confirm')}</Button>
							</Box>
							<Box display="flex" justifyContent="center" p={3}>
								<Button
									color="secondary"
									variant="contained"
									onClick={() => { history.replace('/') }}
								>{t('back')}</Button>
							</Box>
						</form>
						</Grid>
					</Grid>
				</Box>
			</Card>
		</Container>
	)
}